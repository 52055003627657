import NavigationBar from "components/navigation-bar/NavigationBar";
import { logout, setToken } from "helpers/auth-functions";
import PhoneLogin from "pages/login/PhoneLogin";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getLoggedUserInfo, getLoggedUserSocials } from "redux-context/user";

const PhoneOnboarding: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();

  const onLoginPhone = (response: { accessToken: string }) => {
    setToken(response.accessToken);
    dispatch(getLoggedUserInfo());
    dispatch(getLoggedUserSocials());
    navigate("/welcome-name");
  };

  return (
    <div className="login">
      <NavigationBar onBack={() => logout()} />

      <div style={{ margin: 20 }}>
        <div className="align-justify-center">
          <PhoneLogin onLogin={onLoginPhone} />
        </div>
      </div>
    </div>
  );
};

export default PhoneOnboarding;
