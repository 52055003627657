import { LoadingOutlined, WarningOutlined } from "@ant-design/icons";
import { Chat } from "types/chat";
import { getRelativeTimeString } from "../utils";

export const FoodieMessage = ({
  content,
  state,
  createdAt,
  sender,
  _id,
}: Chat["messages"][number]) => {
  return (
    <>
      <div
        key={_id}
        style={{
          display: "flex",
          wordBreak: "break-word",
          gap: 10,
          flexDirection: "row-reverse",
        }}
      >
        <div
          style={{
            backgroundColor: "#FFAC39",
          }}
          className="message"
        >
          {content}
        </div>
        {state === "sending" && <LoadingOutlined style={{ marginLeft: 5 }} />}
        {state === "error" && (
          <WarningOutlined style={{ marginLeft: 5, color: "red" }} />
        )}
      </div>
      <div style={{ textAlign: "right", fontSize: 12, color: "#999999" }}>
        {getRelativeTimeString(new Date(createdAt))}
      </div>
    </>
  );
};
