import { useEffect } from "react";

declare global {
  interface Window {
    FB: any;
    fbAsyncInit: any;
  }
}

const permissions = [
  "public_profile",
  "email",
  "pages_show_list",
  "instagram_basic",
];

const checkLoginState = (callback: (response: any) => void) => {
  window.FB.getLoginStatus((response: any) => {
    callback(response);
  });
};

const { REACT_APP_FACEBOOK_APP_ID } = process.env;

export const handleFacebookLogin = (): Promise<any> => {
  return new Promise((resolve, reject) => {
    window.FB.login(
      (response: any) => {
        if (response.authResponse) {
          checkLoginState(resolve);
        } else {
          reject(new Error("User cancelled login or did not fully authorize."));
        }
      },
      {
        scope: permissions.join(","),
        enable_profile_selector: true,
      }
    );
  });
};

export const fetchUserProfile = async () => {
  try {
    const response: any = await new Promise((resolve, reject) => {
      window.FB.api("/me", (response: any) => {
        if (response.error) {
          reject(response.error);
        } else {
          resolve(response);
        }
      });
    });

    // console.log("Successful login for: ", response);
    return response;
  } catch (error) {
    console.error("Error fetching user profile:", error);
  }
};

export const fetchUserPermissions = async () => {
  try {
    const response: any = await new Promise((resolve, reject) => {
      window.FB.api("/me/permissions", (response: any) => {
        if (response.error) {
          reject(response.error);
        } else {
          resolve(response);
        }
      });
    });

    const { acceptedPermissions, declinedPermissions } = response.data.reduce(
      (
        acc: { acceptedPermissions: string[]; declinedPermissions: string[] },
        p: any
      ) => {
        if (p.status === "granted") {
          acc.acceptedPermissions.push(p.permission);
        } else if (p.status === "declined") {
          acc.declinedPermissions.push(p.permission);
        }
        return acc;
      },
      { acceptedPermissions: [], declinedPermissions: [] }
    );

    return { acceptedPermissions, declinedPermissions };
  } catch (error) {
    console.error("Error fetching user permissions:", error);
  }
};

export const FacebookSDKLoader = () => {
  useEffect(() => {
    window.fbAsyncInit = () => {
      window.FB.init({
        appId: REACT_APP_FACEBOOK_APP_ID,
        cookie: true,
        xfbml: true,
        version: "v13.0",
      });
      console.log("Facebook SDK loaded");
      window.FB.AppEvents.logPageView();
    };

    // Carrega o SDK do Facebook
    (function (d, s, id) {
      let js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s) as HTMLScriptElement;
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode!.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }, []);
  return null;
};
