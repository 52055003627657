import { ClockCircleOutlined, LeftOutlined } from "@ant-design/icons";
import { Button, Row, Typography } from "antd";
import dayjs from "dayjs";
import { useMemo, useState } from "react";
import {
  NavLink,
  useLoaderData,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { ReactComponent as LocationIcon } from "assets/images/location-icon.svg";
import { getGoogleMapsUrl } from "helpers/google-maps-utils";
import { useDispatch, useSelector } from "react-redux";
import { getLoggedUserSocials, UserState } from "redux-context/user";
import "./Collabs.scss";
import CollabsConnectSocialDrawer from "pages/connect-social/CollabsConnectSocialDrawer";
import { handleLoginInstagram } from "components/instagram-button/InstagramButton";
import { handleLoginTikTok } from "components/tiktok-buttom/TikTokButton";
import { updateSocialMediaInterest } from "services/SauceService";

type AlternateProps = {
  socialName: "tiktok" | "instagram" | "meta";
};

const AlternateSocialConnect: React.FC<AlternateProps> = (props) => {
  const { socialName } = props;
  const dispatch = useDispatch<any>();
  const [visible, setVisible] = useState<boolean>(true);

  const capitalizedName = useMemo(() => {
    return socialName.charAt(0).toUpperCase() + socialName.slice(1);
  }, [socialName]);

  const navigate = useNavigate();

  const onClickUnlock = () => {
    if (socialName === "instagram") {
      handleLoginInstagram();
      return;
    }

    if (socialName === "tiktok") {
      handleLoginTikTok();
      return;
    }

    if (socialName === "meta") {
      navigate("/facebook-disclaimer");
      return;
    }
  };

  const onClickNotInterested = async () => {
    console.log("not interested");
    const params = {
      socialMediaName: socialName,
      notInterested: true,
    };
    setVisible(false);
    await updateSocialMediaInterest(params);
    dispatch(getLoggedUserSocials());
  };

  if (!visible) return null;

  return (
    <div
      className="blue-box"
      style={{ marginTop: 40, display: "flex", gap: 12 }}
    >
      <div>
        <b>🔒</b>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: 12,
        }}
      >
        <p style={{ margin: 0 }}>
          <b>Do you also have {capitalizedName}?</b> Connect to potentially get
          higher payouts.
        </p>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Button
            type="primary"
            size="small"
            shape="round"
            style={{ backgroundColor: "transparent", color: "#1d2023e0" }}
            onClick={onClickUnlock}
          >
            Unlock
          </Button>
          <Button
            type="link"
            size="small"
            style={{ padding: 0 }}
            onClick={onClickNotInterested}
          >
            Not interested
          </Button>
        </div>
      </div>
    </div>
  );
};

const weekDays = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

const CollabDetail: React.FC = () => {
  const { collab } = useLoaderData() as any;
  const navigate = useNavigate();
  const location = useLocation();
  const { loggedUser, socialConnected, connectedSocials } = useSelector(
    (state: UserState) => state.user
  );
  const { state } = location;
  const filteredConnectedSocials =
    connectedSocials?.filter(
      (social) => social.notInterested === false && social.dateConnected == null
    ) || [];

  const daysOfWeek = useMemo(() => {
    return (
      collab?.bookingAvailability
        ?.filter((day: any) => day.open !== false)
        .map((day: any) => day?.dayOfWeek?.toLowerCase()) || []
    );
  }, [collab]);

  const openDay = (day: string) => {
    if (daysOfWeek.includes(day)) return "open";
    return "";
  };

  return (
    <div style={{ padding: 20 }} className="collab-detail">
      <Row>
        <LeftOutlined
          style={{ cursor: "pointer" }}
          onClick={() => navigate(-1)}
        />
        {/* <span style={{ flex: 1, textAlign: "center" }}>⭐️4.5 {" ∘ "} $$$</span> */}
      </Row>
      <div style={{ overflow: "auto" }}>
        <Typography.Title level={1} style={{ marginTop: 0 }}>
          {collab.venue.name}
        </Typography.Title>
        <Row style={{ gap: 8 }}>
          <ClockCircleOutlined style={{ fontSize: 18 }} /> {" from "}
          {dayjs.utc(collab.startDate).format("MMM DD")}
        </Row>
        <div className="week-list" style={{ marginLeft: 24, marginTop: 6 }}>
          {weekDays.map((day) => (
            <span key={day} className={`day ${openDay(day)}`}>
              {day[0].toUpperCase()}
            </span>
          ))}
        </div>
        <Row style={{ marginTop: 12, gap: 6 }}>
          <LocationIcon />
          <a
            href={getGoogleMapsUrl(collab.venue)}
            target="_blank"
            rel="noreferrer"
            className="ellipsis"
            style={{ width: 300 }}
          >
            {collab.venue.addressDisplay ?? collab.venue.address}
          </a>
        </Row>
        <Row style={{ gap: 8, marginTop: 12 }}>
          {collab.guestAllowed && (
            <div className="box-perk">
              🍴
              <div className="value">
                <span>Guests</span>
                <span>
                  <b>+1</b>
                </span>
              </div>
            </div>
          )}
          {collab.compensationFoodNumber > 0 && (
            <div className="box-perk">
              🍔
              <div className="value">
                <span>Food</span>
                <span
                  style={{
                    filter: !socialConnected ? "blur(3px)" : "none",
                  }}
                >
                  <b>${collab.compensationFoodNumber}</b>
                </span>
              </div>
            </div>
          )}
          {collab.compensationCashNumber > 0 && (
            <div className="box-perk">
              💸
              <div className="value">
                <span>Comp</span>
                <span
                  style={{
                    filter: !socialConnected ? "blur(3px)" : "none",
                  }}
                >
                  <b>${collab.compensationCashNumber}</b>
                </span>
              </div>
            </div>
          )}
        </Row>
        {socialConnected ? (
          filteredConnectedSocials?.map((social, index) => (
            <AlternateSocialConnect
              socialName={social.socialMediaName}
              key={`${social.socialMediaName}_${index}`}
            />
          ))
        ) : (
          <div style={{ marginTop: 12 }}>
            <CollabsConnectSocialDrawer />
          </div>
        )}

        {loggedUser?.verificationNeeded ? (
          <div className="blue-box" style={{ marginTop: 40 }}>
            Your account is being verified. Please ensure your
            <NavLink
              to="/profile/notifications"
              style={{ color: "#1d2023e0", fontWeight: "bold" }}
            >
              {" phone number "}
            </NavLink>
            and
            <NavLink
              to="/profile/notifications"
              style={{ color: "#1d2023e0", fontWeight: "bold" }}
            >
              {" email "}
            </NavLink>
            are up-to-date to receive notifications promptly.
          </div>
        ) : socialConnected ? (
          <Row style={{ marginTop: 24 }}>
            <Button
              type="primary"
              onClick={() =>
                navigate(`/collab/book/${collab.collabId}`, { state })
              }
              className="button-with-content"
            >
              <span className="main">Request a visit</span>
              <span>{`only ${collab?.numberOfFoodiesRemaining} spots left`}</span>
            </Button>
          </Row>
        ) : null}
        <Row>
          <div className="briefing" style={{ marginBottom: 80 }}>
            <span className="title">Brief:</span>
            <Typography.Paragraph>{collab.brief}</Typography.Paragraph>

            <span className="title">Deliverables:</span>
            <ul>
              {collab?.deliverables?.instagramReel && (
                <li className="circle checkmark">Instagram Reel</li>
              )}
              {collab?.deliverables?.instagramStory && (
                <li className="circle checkmark">Instagram Story</li>
              )}
              {collab?.deliverables?.tiktokVideo && (
                <li className="circle checkmark">TikTok Video</li>
              )}
            </ul>
          </div>
        </Row>
      </div>
    </div>
  );
};

export default CollabDetail;
