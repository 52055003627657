import { Button } from "antd";
import { ReactComponent as InstagramIcon } from "assets/images/instagram-icon.svg";

const { REACT_APP_INSTAGRAM_CLIENT_ID, REACT_APP_CLIENT_URL } = process.env;

export const handleLoginInstagram = () => {
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({ type: "instagram_auth" })
    );
    return;
  }

  const instagramLoginUrl = `https://www.instagram.com/oauth/authorize?enable_fb_login=0&force_authentication=1&client_id=${REACT_APP_INSTAGRAM_CLIENT_ID}&redirect_uri=${REACT_APP_CLIENT_URL}instagram-auth&response_type=code&scope=instagram_business_basic`;
  window.location.href = instagramLoginUrl;
};

const InstagramButton: React.FC<{ asLabel?: boolean }> = (props) => {
  const { asLabel } = props;

  if (asLabel) {
    return (
      <div className="media" style={{ cursor: "pointer" }}>
        <InstagramIcon />
        <span onClick={handleLoginInstagram}>
          <b>Please connect your Instagram</b>
        </span>
      </div>
    );
  }
  return (
    <Button
      style={{ borderRadius: 12 }}
      icon={<InstagramIcon />}
      onClick={handleLoginInstagram}
    />
  );
};

export default InstagramButton;
