declare global {
  interface Window {
    ReactNativeWebView: any;
    sauceToken?: string;
  }
}

export const TOKEN = "sauce-info";

export const setToken = (token: string) => {
  localStorage.setItem(TOKEN, token);
  sessionStorage.setItem(TOKEN, token);
};

export const getToken = () => {
  return localStorage.getItem(TOKEN) || sessionStorage.getItem(TOKEN);
};

export const autenticado = () => {
  return (
    localStorage.getItem(TOKEN) != null || sessionStorage.getItem(TOKEN) != null
  );
};

export const setCookie = (name: string, value: string, days: number) => {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  document.cookie = `${name}=${value};expires=${date.toUTCString()};path=/`;
};

export const getCookie = (name: string) => {
  const match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"));
  return match ? match[2] : null;
};

export const logout = () => {
  const token = getToken();
  localStorage.clear();
  sessionStorage.clear();
  const returnRoute = window.location.pathname;

  const event = {
    type: token ? "logout" : "missing_token",
    returnRoute,
  };

  window.ReactNativeWebView?.postMessage(JSON.stringify(event));
  setTimeout(() => {
    window.location.href = "/login";
  }, 1000);
};
