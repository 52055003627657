import { logout, setToken } from "helpers/auth-functions";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getLoggedUserInfo,
  getLoggedUserSocials,
  UserState,
} from "redux-context/user";
import LoadingFullScreen from "./LoadingFullScreen";

interface SigninAppProps {}

const SigninApp: React.FC<SigninAppProps> = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("accessToken");
  const returnRoute = queryParams.get("returnRoute");
  const { loggedUser, loading } = useSelector((state: UserState) => state.user);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (token) {
      setToken(token);
      dispatch(getLoggedUserInfo());
      dispatch(getLoggedUserSocials());
      timeout = setTimeout(() => {
        navigate(".", { replace: true });
      }, 10000);
    } else {
      console.log("no token");
      logout();
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [token, dispatch, navigate]);

  useEffect(() => {
    if (!loading) {
      if (loggedUser._id) {
        if (returnRoute) {
          navigate(returnRoute);
        } else {
          navigate("/");
        }
      } else {
        logout();
      }
    }
  }, [loggedUser, returnRoute, loading, navigate]);

  return <LoadingFullScreen />;
};

export default SigninApp;
