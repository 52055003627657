import AppleSignin from "react-apple-signin-auth";
import "./AppleButton.scss";
import { signInApple } from "services/SauceService";
import { useNavigate } from "react-router-dom";

// interface SignInWithAppleRequest {
//   authorizationCode: string | null;
//   email: string | null;
//   identityToken: string | null;
//   user: string | null;
//   appVersion?: string | null;
//   // fullName: {
//   //   givenName: string;
//   // };
// }

type AppleResponse = {
  authorization: {
    code: string | null;
    id_token: string | null;
  };
  user?: {
    email: string;
    name: {
      firstName: string;
      lastName: string;
    };
  };
};

const { REACT_APP_CLIENT_URL } = process.env;

const AppleButton = ({ ...rest }) => {
  const navigate = useNavigate();

  const onSignInWithApple = async (response: AppleResponse) => {
    const params = {
      authorizationCode: response?.authorization.code,
      identityToken: response?.authorization.id_token,
      fullName: response?.user
        ? response?.user?.name?.firstName + response?.user?.name?.lastName
        : undefined,
      web: true,
    };
    const loginInfo: any = await signInApple(params);
    // Replace with your backend endpoint to exchange the code for an access token
    navigate("/signin-link?accessToken=" + loginInfo.accessToken);
  };

  return (
    <AppleSignin
      /** Auth options passed to AppleID.auth.init() */
      authOptions={{
        clientId: "love.mustard.hero.web",
        scope: "email name",
        redirectURI: `${REACT_APP_CLIENT_URL}apple-auth`,
        state: "",
        nonce: "nonce",
        usePopup: true,
      }}
      uiType="dark"
      className="apple-auth-button"
      noDefaultStyle
      // iconProps={{
      //   width: 20,

      // }}
      buttonExtraChildren="Continue with Apple"
      onSuccess={onSignInWithApple}
      onError={(error: any) => console.error(error)}
      {...rest}
    />
  );
};

export default AppleButton;
