import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingFullScreen from "./LoadingFullScreen";
import {
  fetchSauceContactInfo,
  sauceVerification,
  signInSauce,
} from "services/SauceService";
import { Button, Form, Input, message } from "antd";

type ContactInfo = {
  email: string;
  phone: string;
};

const SigninSauce: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get("user");
  const returnRoute = queryParams.get("returnRoute") || "/";
  const [loading, setLoading] = useState<boolean>(false);
  const [contactInfo, setContactInfo] = useState<ContactInfo>();
  const [emailSent, setEmailSent] = useState<boolean>(false);
  const [smsSent, setSmsSent] = useState<boolean>(false);

  const sauceUserVerification = useCallback(
    async (type: "phone" | "email") => {
      if (userId) {
        const response: any = await sauceVerification({ userId, type });

        if (response?.accessToken) {
          navigate(
            `/signin-link?accessToken=${response.accessToken}&returnRoute=${returnRoute}`
          );
        } else {
          setLoading(false);
          if (type === "email") {
            setEmailSent(true);
          }
          if (type === "phone") {
            setSmsSent(true);
          }
        }
      }
    },
    [userId, returnRoute, navigate]
  );

  const getSauceContactInfo = useCallback(async () => {
    if (userId) {
      const response: any = await fetchSauceContactInfo({ userId });
      setContactInfo(response);
    }
  }, [userId]);

  const onFinishForm = async () => {
    setLoading(true);
    const { code } = form.getFieldsValue(true);
    try {
      const response: any = await signInSauce({ userId, code });
      if (response?.accessToken) {
        navigate(
          `/signin-link?accessToken=${response.accessToken}&returnRoute=${returnRoute}`
        );
      } else {
        setLoading(false);
      }
    } catch (error: any) {
      message.error(error?.message || "An error occurred");
      setLoading(false);
    }
  };

  useEffect(() => {
    getSauceContactInfo();
  }, [getSauceContactInfo]);

  if (loading) {
    return <LoadingFullScreen />;
  }

  return (
    <div className="login">
      <div style={{ margin: 20 }}>
        <div className="align-justify-center">
          {" "}
          <Form form={form} onFinish={onFinishForm}>
            <span className="heading-1-32-bold" style={{ display: "block" }}>
              Verification code{" "}
            </span>
            {contactInfo?.email || contactInfo?.phone ? (
              <>
                <span
                  className="body-16-regular"
                  style={{ display: "block", marginBottom: 20, maxWidth: 350 }}
                >
                  A verification code will be sent to the contact information we
                  have on file.
                </span>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 12,
                    marginBottom: 20,
                  }}
                >
                  {contactInfo?.email && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: 20,
                      }}
                    >
                      <span
                        className="body-16-regular"
                        style={{ display: "block" }}
                      >
                        Verify via email: {contactInfo.email}
                      </span>
                      <Button
                        type="primary"
                        size="small"
                        shape="round"
                        onClick={() => sauceUserVerification("email")}
                        htmlType="button"
                        disabled={emailSent}
                      >
                        Send
                      </Button>
                    </div>
                  )}

                  {contactInfo?.phone && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: 20,
                      }}
                    >
                      <span
                        className="body-16-regular"
                        style={{ display: "block" }}
                      >
                        Verify via phone: {contactInfo.phone}
                      </span>
                      <Button
                        type="primary"
                        size="small"
                        shape="round"
                        onClick={() => sauceUserVerification("phone")}
                        htmlType="button"
                        disabled={smsSent}
                      >
                        Send
                      </Button>
                    </div>
                  )}
                </div>
                {(emailSent || smsSent) && (
                  <>
                    <Form.Item
                      name="code"
                      rules={[
                        {
                          required: true,
                          message: "Please input the verification code!",
                        },
                      ]}
                    >
                      <Input.OTP
                        length={6}
                        onChange={() => form.submit()}
                        style={{ width: "100%" }}
                        mask
                      />
                    </Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ width: "100%" }}
                      loading={loading}
                    >
                      Verify
                    </Button>
                  </>
                )}
              </>
            ) : (
              <span
                className="body-16-regular"
                style={{ display: "block", marginBottom: 20, maxWidth: 350 }}
              >
                We were not able to verify your contact information, please
                reach out to us at{" "}
                <a href="mailto:contact@mustard.love">contact@mustard.love.</a>{" "}
                with the code {userId}.
              </span>
            )}
          </Form>
        </div>
      </div>
    </div>
  );
};

export default SigninSauce;
