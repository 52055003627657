import { Avatar } from "antd";
import { Chat } from "types/chat";
import { getRelativeTimeString } from "../utils";

export const BusinessMessage = ({
  content,
  state,
  sender,
  createdAt,
  _id,
}: Chat["messages"][number]) => {
  return (
    <div>
      <div
        key={_id}
        style={{
          display: "flex",
          wordBreak: "break-word",
          gap: 10,
          marginBottom: 10,
          flexDirection: "row",
          marginRight: 10,
        }}
      >
        <div>
          <Avatar src={sender.avatarUrl} />
        </div>
        <div className="message">
          {content}
          <div
            style={{
              fontSize: 12,
              color: "#888",
              marginTop: 5,
              textAlign: "left",
            }}
          >
            {sender.name}
          </div>
        </div>
      </div>
      <div
        style={{
          paddingLeft: 50,
          fontSize: 12,
          color: "#999999",
        }}
      >
        {getRelativeTimeString(new Date(createdAt))}
      </div>
    </div>
  );
};
