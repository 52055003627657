import { useEffect, useState } from "react";
import { Button, Avatar, Input, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { hideMenu, showMenu } from "redux-context/page";
import { ReactComponent as ShortLogo } from "assets/images/short-logo.svg";
import NavigationBar from "components/navigation-bar/NavigationBar";
import { useNavigate } from "react-router-dom";
import { getLoggedUserInfo, UserState } from "redux-context/user";
import { ProfileStatus } from "interfaces/user-profile";
import { valueType } from "antd/es/statistic/utils";
import { hideWelcome, updateUserInfoGeneral } from "services/SauceService";
import "./WelcomeName.scss";
import { logout } from "helpers/auth-functions";

const WelcomeName: React.FC = () => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const { loggedUser } = useSelector((state: UserState) => state.user);
  const [name, setName] = useState<valueType>("");
  const [loading, setLoading] = useState<boolean>(false);

  const onClickStart = () => {
    hideWelcome();
    navigate("/");
  };

  const saveName = async () => {
    setLoading(true);
    try {
      await updateUserInfoGeneral({ ...loggedUser, name: name });
      dispatch(getLoggedUserInfo());
    } catch {
      message.error("Failed to update user information. Please try again.");
    }
    setLoading(false);
  };

  useEffect(() => {
    dispatch(hideMenu());
    return () => {
      dispatch(showMenu());
    };
  }, [dispatch]);

  if (loggedUser.profileStatus === ProfileStatus.MissingName)
    return (
      <div>
        <NavigationBar onBack={() => logout()} />
        <div className="welcome-card">
          <h1 className="heading-1-32-bold" style={{ margin: 0 }}>
            Tell us your name
          </h1>
          <span className="body-16-regular" style={{ margin: "12px 0 32px 0" }}>
            We're excited to meet you!
          </span>
          <Input
            placeholder="Your name"
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
          <Button
            type="primary"
            className="welcome-card__start-button subtitle-18-regular-poppins"
            style={{ marginTop: 20 }}
            onClick={saveName}
            loading={loading}
          >
            Continue
          </Button>
        </div>
      </div>
    );

  return (
    <div className="welcome-card" style={{ height: "100vh" }}>
      <div>
        <div className="welcome-card__header">
          <Avatar size={80} src={<ShortLogo style={{ width: 40 }} />} />
        </div>
        <h2 className="heading-2-26-bold">
          Welcome to Mustard, {loggedUser?.name}
        </h2>
        <p className="body-16-semi-bold-poppins">Your next steps:</p>

        <ul className="welcome-card__steps-list">
          <li className="welcome-card__step-item">
            🔍
            <div>
              <span className="body-16-semi-bold-inter">
                Find local collabs near you
              </span>
              <p className="welcome-card__step-description body-16-regular">
                Share your location to unlock exclusive restaurant collabs
                nearby.
              </p>
            </div>
          </li>
          <li className="welcome-card__step-item">
            🔔
            <div>
              <span className="body-16-semi-bold-inter">
                Be the first to apply and get approved
              </span>
              <p className="welcome-card__step-description body-16-regular">
                Enable push notifications to never miss amazing collabs.
              </p>
            </div>
          </li>
          <li className="welcome-card__step-item">
            ✔️
            <div>
              <span className="body-16-semi-bold-inter">
                Earn free food and cash rewards
              </span>
              <p className="welcome-card__step-description body-16-regular">
                Verify your Mustard account to enjoy meals and cash rewards.
              </p>
            </div>
          </li>
          <li className="welcome-card__step-item">
            😎
            <div>
              <span className="body-16-semi-bold-inter">
                Get VIP treatment every time
              </span>
              <p className="welcome-card__step-description body-16-regular">
                Avoid no-shows, share honest reviews.
              </p>
            </div>
          </li>
        </ul>
      </div>
      <Button
        type="primary"
        className="welcome-card__start-button subtitle-18-regular-poppins"
        onClick={onClickStart}
      >
        Let's start
      </Button>
    </div>
  );
};

export default WelcomeName;
