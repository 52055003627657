import { Button } from "antd";
import successBg from "assets/images/collab-success.png";
import { useNavigate } from "react-router-dom";

type VerificationProps = {
  vendor: string;
};
const VerificationSuccess: React.FC<VerificationProps> = (props) => {
  const { vendor } = props;
  const navigate = useNavigate();

  const onClickGotIt = () => {
    navigate("/");
  };
  
  return (
    <div className="page-full-height green">
      <div
        style={{
          padding: "0 20px",
          flex: 1,
        }}
      >
        <h1 className="heading-1-32-bold">
          Verification request for {vendor} account received!
        </h1>
        <p className="body-16-regular-poppins">
          We'll review it within 2 business days and notify you.
        </p>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img src={successBg} alt="" style={{ width: 220 }} />
        </div>
      </div>
      <div
        style={{
          padding: 20,
        }}
      >
        <Button
          type="primary"
          size="large"
          shape="round"
          onClick={onClickGotIt}
          style={{ marginTop: 24, width: "100%" }}
        >
          Got it
        </Button>
      </div>
    </div>
  );
};

export default VerificationSuccess;
