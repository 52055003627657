import "./messenger.scss";
import { Avatar, Badge, Typography } from "antd";
import { useEffect, useState } from "react";
import { getChats } from "services/SauceService";
import { useNavigate } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import EmptyItems from "assets/images/empty-item.png";
import { Chat } from "types/chat";
import { ReactComponent as LocationIcon } from "assets/images/location-icon.svg";
import { getRelativeTimeString } from "./utils";
import { getParticipantsByType } from "./utils";
import { ReactComponent as ShortLogo } from "assets/images/short-logo.svg";

interface MessengerProps {
  // Add any props you need here
}

const Messenger: React.FC<MessengerProps> = () => {
  const navigate = useNavigate();
  const [chats, setChats] = useState<Chat[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchChats = async () => {
      try {
        setIsLoading(true);
        const response = await getChats();
        //@ts-ignore
        setChats(response.chats);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchChats();
  }, []);

  return (
    <div className="messenger" style={{ paddingBottom: 100 }}>
      <Typography.Title className="title">Messages</Typography.Title>
      <>
        {isLoading && (
          <div className="loading-container">
            <LoadingOutlined style={{ color: "#ffac39", fontSize: 48 }} spin />
          </div>
        )}
      </>
      <>
        {!isLoading && chats.length === 0 && (
          <div className="box-item" style={{ justifyContent: "center" }}>
            <div className="item" style={{ textAlign: "center" }}>
              <div className="image-container" style={{ marginBottom: 20 }}>
                <img src={EmptyItems} alt="" />
              </div>
              <div style={{ fontSize: 22, fontWeight: 500 }}>No chats yet</div>
              <div style={{ fontSize: 16 }}>Your chat history is empty</div>
            </div>
          </div>
        )}
      </>

      {!isLoading && (
        <div className="list-chat">
          {chats.map((chat) => {
            const contextType = chat.context.type;
            const { business } = getParticipantsByType(chat);
            const lastMessage = chat.messages[chat.messages.length - 1];
            const lastMessageRelativeDate = lastMessage
              ? getRelativeTimeString(new Date(lastMessage.createdAt))
              : "";

            return (
              <div
                className={`card ${chat.unreadCount === 0 ? "faded" : ""}`}
                onClick={() => navigate(`/messenger/${chat._id}`)}
              >
                <div className={`box-item `}>
                  <div className="item">
                    <div className="chat-info">
                      <div className="icon">
                        {contextType === "collab" ? (
                          <Avatar size={48} src={business?.avatarUrl} />
                        ) : (
                          <Avatar size={48} src={<ShortLogo />} />
                        )}
                      </div>
                      <div>
                        <div className="title">
                          {chat.context.title}
                          <div className="date">
                            {lastMessageRelativeDate}
                            {chat.unreadCount > 0 && (
                              <span style={{ marginLeft: 5 }}>
                                <Badge
                                  count={chat.unreadCount}
                                  style={{
                                    backgroundColor: "#ffac39",
                                    color: "black",
                                  }}
                                />
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="address">
                          {contextType === "collab" && (
                            <>
                              <span
                                style={{ verticalAlign: "sub", marginRight: 5 }}
                              >
                                <LocationIcon />
                              </span>
                              {chat.context?.venue?.address}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="last-message">{lastMessage?.content}</div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Messenger;
