import React, { useState } from "react";
import { Drawer } from "antd";
import { isDesktop } from "react-device-detect";

type UseDrawerResult = {
  openDrawer: () => void;
  closeDrawer: () => void;
  DrawerComponent: JSX.Element;
};

export function useConstantDrawer(
  content: React.ReactNode,
  title: string,
  width: number = 400
): UseDrawerResult {
  const [isVisible, setIsVisible] = useState(false);

  const openDrawer = () => {
    setIsVisible(true);
  };

  const closeDrawer = () => {
    setIsVisible(false);
  };

  const DrawerComponent = (
    <Drawer
      title={title}
      open={isVisible}
      onClose={closeDrawer}
      width={width}
      placement="bottom"
      rootClassName="drawer-rating"
      classNames={{
        header: "collabs-drawer-header",
        body: "collabs-drawer-body",
      }}
      styles={{
        header: {
          border: "none",
          // width: 400,
        },
        // content: {
        //   alignItems: "center",
        // },
        body: {
          marginLeft: isDesktop ? 265 : 0,
          justifyContent: "center",
          display: "flex",
        },
      }}
    >
      {content}
    </Drawer>
  );

  return {
    openDrawer,
    closeDrawer,
    DrawerComponent,
  };
}
