import {
  InfoCircleOutlined,
  LeftOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { Button, Popover, Row, Typography } from "antd";
import { Booking } from "interfaces/booking";
import { useLocation, useNavigate } from "react-router-dom";
import "./Confirm.scss";
import { useCallback, useEffect, useState } from "react";
import { isDesktop, isTablet } from "react-device-detect";
import { setBackgroundColor } from "redux-context/page";
import { useDispatch } from "react-redux";
import { confirmBooking, getBookingById } from "services/SauceService";

const Confirm: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const { state } = location || {};
  const { collab }: { collab: Booking } = state || {};
  const [bookingInfo, setBookingInfo] = useState<Booking>();

  const getBookInfo = useCallback(async () => {
    const booking: any = await getBookingById(collab.bookingId);
    setBookingInfo(booking);
  }, [collab]);

  const containDeliverable = (deliverable: string) => {
    return bookingInfo?.deliverables
      .map((item) => item.toLowerCase())
      .find((item) => item.includes(deliverable));
  };

  const keepOnlyOneAt = (profile: string) => {
    const regex = /@+/;
    const match = profile.match(regex);
    if (match) {
      return profile.replace(match[0], "@");
    }
    return profile;
  };

  const onClickConfirm = async () => {
    setLoading(true);

    const { collabId, bookingId } = collab;
    try {
      await confirmBooking({ collabId, bookingId });
      navigate("/confirmed", {
        state: { collab },
      });
      dispatch(setBackgroundColor("#e2f2dc"));
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getBookInfo();
  }, [getBookInfo]);

  return (
    <div className="page-full-screen confirm">
      <Row style={{ alignItems: "center", gap: 12 }}>
        <LeftOutlined
          style={{ cursor: "pointer" }}
          onClick={() => navigate(-1)}
        />
        <Typography.Title level={3} style={{ margin: 0 }}>
          Check the Brief
        </Typography.Title>

        <div className="briefing" style={{ marginBottom: 80 }}>
          <span className="title">Brief:</span>
          <Typography.Paragraph>
            {bookingInfo?.collabDetails?.brief}
          </Typography.Paragraph>
          <span className="title">Instructions:</span>
          <Typography.Paragraph>
            {bookingInfo?.collabDetails?.focusPoints}
          </Typography.Paragraph>

          <span className="title">Deliverables:</span>

          {containDeliverable("instagram reel") && (
            <div>
              <span className="subtitle">Instagram Reel</span>
              {bookingInfo?.compensationCash && (
                <Popover
                  style={{ width: 200 }}
                  title={null}
                  content={
                    <div
                      style={{
                        fontFamily: "Arial, sans-serif",
                        lineHeight: "1.6",
                      }}
                    >
                      <h2>To mark content as sponsored on Instagram</h2>
                      <p>
                        You can use the <strong>Branded Content tool</strong> to
                        add a Paid Partnership Label to your post:
                      </p>
                      <ol>
                        <li>Create your content as usual</li>
                        <li>Tap Next</li>
                        <li>Tap Advanced settings</li>
                        <li>
                          Toggle on <strong>Add paid partnership label</strong>
                        </li>
                        <li>
                          Tap <strong>Add brand partners</strong> to search for
                          and add up to two brands
                        </li>
                        <li>
                          If your agreement allows it, toggle on{" "}
                          <strong>Allow brand partner to promote</strong>
                        </li>
                        <li>Tap Done to post your content</li>
                      </ol>
                      <p>
                        The <strong>"Paid Partnership With"</strong> label will
                        appear above your post to indicate the partnership.
                      </p>
                      <p>
                        You can also access branded content tools by tapping{" "}
                        <strong>Creator</strong> in your settings, then tapping{" "}
                        <strong>Branded content</strong>.
                      </p>
                    </div>
                  }
                >
                  <InfoCircleOutlined style={{ marginLeft: 6 }} />
                </Popover>
              )}
              <ul>
                {bookingInfo?.collabDetails?.promotionOfferSummary && (
                  <li className="circle checkmark">
                    Add offer{" "}
                    {bookingInfo?.collabDetails?.promotionOfferSummary}
                  </li>
                )}
                {bookingInfo?.collabDetails?.instagramAccount && (
                  <li className="circle checkmark">
                    Tag{" "}
                    {keepOnlyOneAt(
                      `@${bookingInfo?.collabDetails.instagramAccount}`
                    )}{" "}
                    as a collaborator
                  </li>
                )}

                <li className="circle checkmark">
                  Tag @mustard.love as a collaborator
                </li>
                {bookingInfo?.compensationCash && (
                  <li className="circle checkmark">
                    Add Paid Partnership Label
                  </li>
                )}

                {bookingInfo?.collabDetails?.promotionActionDm && (
                  <li className="circle checkmark">
                    Ask your followers to DM{" "}
                    {bookingInfo?.collabDetails?.promotionActionDm} to receive{" "}
                    {bookingInfo?.collabDetails?.promotionOfferSummary}
                  </li>
                )}
              </ul>
            </div>
          )}

          {containDeliverable("instagram story") && (
            <div>
              <span className="subtitle">Instagram Story</span>
              <ul>
                {bookingInfo?.collabDetails?.promotionOfferSummary && (
                  <li className="circle checkmark">
                    Add story with offer{" "}
                    {bookingInfo?.collabDetails?.promotionOfferSummary}
                  </li>
                )}
                {bookingInfo?.collabDetails?.instagramAccount && (
                  <li className="circle checkmark">
                    Tag{" "}
                    {keepOnlyOneAt(
                      `@${bookingInfo?.collabDetails.instagramAccount}`
                    )}
                  </li>
                )}
                <li className="circle checkmark">Tag @mustard.love</li>
                {bookingInfo?.collabDetails?.promotionSwipeLink && (
                  <li className="circle checkmark">
                    Add this swipe-up link to your story <br />
                    {bookingInfo?.collabDetails?.promotionSwipeLink}
                  </li>
                )}
                {bookingInfo?.collabDetails?.promotionCaptionLink && (
                  <li className="circle checkmark">
                    Use this caption for the swipe-up <br />
                    {bookingInfo?.collabDetails?.promotionCaptionLink}
                  </li>
                )}
              </ul>
            </div>
          )}
          {containDeliverable("tiktok video") && (
            <div>
              <span className="subtitle">TikTok Video</span>
              <ul>
                {bookingInfo?.collabDetails?.promotionOfferSummary && (
                  <li className="circle checkmark">
                    Add video with offer{" "}
                    {bookingInfo?.collabDetails?.promotionOfferSummary}
                  </li>
                )}

                {bookingInfo?.collabDetails?.tiktokAccount && (
                  <li className="circle checkmark">
                    Tag{" "}
                    {keepOnlyOneAt(
                      `@${bookingInfo?.collabDetails.tiktokAccount}`
                    )}{" "}
                  </li>
                )}
                <li className="circle checkmark">Tag @mustard.love</li>
              </ul>
            </div>
          )}
        </div>
      </Row>
      <Button
        type="primary"
        className={
          isTablet || isDesktop ? "confirm-button" : "floating-confirm-button"
        }
        onClick={onClickConfirm}
      >
        {loading ? <LoadingOutlined spin style={{ fontSize: 24 }} /> : "Got it"}
      </Button>
    </div>
  );
};

export default Confirm;
