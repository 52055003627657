import { message } from "antd";
import { setToken } from "helpers/auth-functions";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connectInstagram } from "services/SauceService";
import LoadingFullScreen from "pages/login/LoadingFullScreen";
import { getLoggedUserInfo, getLoggedUserSocials } from "redux-context/user";
import { useDispatch } from "react-redux";
import VerificationSuccess from "components/verification-success/VerificationSuccess";
import { updateTokenEvent } from "helpers/event-messages";
import { appLog } from "helpers/app-log";

const InstagramAuth: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const handleAuth = async () => {
      setLoading(true);
      const params = new URLSearchParams(window.location.search);
      const code = params.get("code");

      if (code) {
        try {
          const params = {
            instagramCode: code,
          };
          const loginInfo: any = await connectInstagram(params);
          updateTokenEvent(loginInfo.accessToken);
          // Replace with your backend endpoint to exchange the code for an access token
          setToken(loginInfo.accessToken);

          dispatch(getLoggedUserInfo());
          dispatch(getLoggedUserSocials());
        } catch (error) {
          appLog(error);
          console.error("Error during authentication:", error);
          message.error("Error during authentication");
          // if (autenticado()) {
          //   navigate("/profile/social-media");
          // } else {
          //   navigate("/login");
          // }
        }
      } else {
        console.error("No code found in URL");
      }
      setLoading(false);
    };

    handleAuth();
  }, [navigate, dispatch]);

  if (loading) {
    return <LoadingFullScreen />;
  }

  return <VerificationSuccess vendor="Instagram" />;
};

export default InstagramAuth;
