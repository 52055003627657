import { Button } from "antd";
import CollabFailedRejected from "assets/images/collab-failed-rejected.png";

const OutdatedApp: React.FC = () => {
  return (
    <div
      className="book-confirmation red"
      style={{
        position: "relative",
        height: "100dvh",
        width: "auto",
      }}
    >
      <div>
        <h1 style={{ margin: "36px 0 16px 0" }}>Your app is outdated</h1>

        <p style={{ margin: 0 }}>
          Please update your app to the latest version to continue using our
          services.
        </p>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img src={CollabFailedRejected} alt="collab" />
        </div>
      </div>
      <div className="buttons">
        <Button
          type="primary"
          onClick={() =>
            window.open(
              "https://apps.apple.com/us/app/mustard-love/id6677056714",
              "_blank",
              "noopener noreferrer"
            )
          }
        >
          Update
        </Button>
      </div>
    </div>
  );
};

export default OutdatedApp;
