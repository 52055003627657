import "./chat.scss";
import { Avatar, Button, Input } from "antd";
import { getChat, sendMessage, markChatAsRead } from "services/SauceService";
import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useRef } from "react";
import { useState } from "react";
import LoadingFullScreen from "pages/login/LoadingFullScreen";
import { UserProfile } from "interfaces/user-profile";
import { useSelector } from "react-redux";
import { LeftOutlined } from "@ant-design/icons/lib/icons";
import { isMobile } from "react-device-detect";
import { FoodieMessage } from "./components/FoodieMessage";
import { BusinessMessage } from "./components/BusinessMessage";
import { MustardMessage } from "./components/MustardMessage";
import { Chat } from "types/chat";
import { sseService } from "services/SSEService";
import { formatBookingDate, getParticipantsByType } from "./utils";
import { ReactComponent as LocationIcon } from "assets/images/location-icon.svg";
import { ReactComponent as SendMessageIcon } from "assets/images/send-message.svg";
import { ActionMessage } from "./components/ActionMessage";

const createDummyMessage = ({
  content,
  loggedUser,
}: {
  content: string;
  loggedUser: UserProfile;
}) => {
  return {
    content,
    messageType: "text",
    state: "sending",
    senderType: "foodie",
    sender: {
      _id: loggedUser._id,
      name: loggedUser.name,
      avatarUrl: loggedUser.avatarUrl,
    },
    _id: Date.now().toString(),
    createdAt: new Date(),
  };
};

const MessengerChat: React.FC = () => {
  const {
    loggedUser,
  }: {
    loggedUser: UserProfile;
  } = useSelector((state: any) => state.user);
  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { id } = useParams();

  const [chat, setChat] = useState<Chat | null>(null);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [isSendingMessage, setIsSendingMessage] = useState(false);

  const scrollToBottom = () => {
    setTimeout(() => {
      if (messagesContainerRef.current) {
        messagesContainerRef.current.scrollTop =
          messagesContainerRef.current.scrollHeight;
      }
    }, 0);
  };

  const markAsRead = useCallback(async (chatId: string) => {
    if (!chatId) return;

    console.log("marking as read");
    try {
      await markChatAsRead(chatId);
    } catch (error) {
      // ignore
    }
  }, []);

  const handleSendMessage = async () => {
    if (!chat || isSendingMessage || !message || message.trim().length === 0) {
      return;
    }

    const originalMessage = message;
    setMessage("");

    try {
      setIsSendingMessage(true);

      setChat({
        ...chat,
        messages: [
          ...chat.messages,
          createDummyMessage({
            content: originalMessage,
            loggedUser,
          }) as Chat["messages"][number],
        ],
      });

      scrollToBottom();

      const messageFromServer = await sendMessage(chat._id, {
        message: originalMessage,
      });
      setChat({
        ...chat,
        //@ts-ignore
        messages: [...chat.messages, messageFromServer],
      });
    } catch (error) {
      setChat({
        ...chat,
        messages: [
          ...chat.messages,
          {
            ...(createDummyMessage({
              content: originalMessage,
              loggedUser,
            }) as Chat["messages"][number]),
            state: "error",
          },
        ],
      });
    } finally {
      setIsSendingMessage(false);
      scrollToBottom();
    }
  };
  const addNewMessage = useCallback((message: Chat["messages"][number]) => {
    setChat((prevChat) => {
      if (!prevChat) return prevChat;

      return {
        ...prevChat,
        messages: [...prevChat.messages, message],
      };
    });
  }, []);

  const onNewMessage = useCallback(
    (event: MessageEvent) => {
      const message = JSON.parse(event.data);
      if (message.chatId !== id) return;

      addNewMessage(message);
      scrollToBottom();

      if (id) {
        markChatAsRead(id);
      }
    },
    [addNewMessage, id]
  );

  const subscribeRef = useRef<(() => void) | null>(null);

  useEffect(() => {
    if (subscribeRef.current) {
      return;
    }

    const unsubscribe = sseService.subscribe("chat/new-message", onNewMessage);
    subscribeRef.current = unsubscribe;

    return () => {
      if (subscribeRef.current) {
        subscribeRef.current();
        subscribeRef.current = null;
      }
    };
  }, [onNewMessage]);

  useEffect(() => {
    const abortController = new AbortController();
    if (!chat && id) {
      getChat(id, abortController)
        .then((res) => {
          //@ts-ignore
          setChat(res);
        })
        .then(() => {
          setLoading(false);
          scrollToBottom();
          markAsRead(id);
        })
        .catch((error) => {
          console.error(error);
        });
    }

    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <LoadingFullScreen />;

  if (!chat) return <div>Chat not found</div>;

  const { foodie, business, mustard } = getParticipantsByType(chat);

  return (
    <div className="chat-page chat">
      <div className="header">
        <div onClick={() => navigate("/messenger")}>
          <LeftOutlined style={{ cursor: "pointer" }} />
        </div>
        <div className="title-container">
          <div className="avatar-container">
            {chat.context.type === "collab" && (
              <Avatar
                size={40}
                src={business?.avatarUrl}
                style={{ zIndex: 2, border: "1px solid rgba(0,0,0,0.1)" }}
              />
            )}
            {chat.context.type === "foodie-mustard" && (
              <Avatar
                size={40}
                src={mustard?.avatarUrl}
                style={{ zIndex: 2, border: "1px solid rgba(0,0,0,0.1)" }}
              />
            )}
            <Avatar
              size={40}
              src={foodie?.avatarUrl}
              style={{
                marginLeft: -10,
                zIndex: 1,
                border: "1px solid rgba(0,0,0,0.1)",
              }}
            />
          </div>
          <div className="info-container">
            <div className="title">
              {chat.context.title}
              {chat.context.type === "collab" && (
                <>
                  <span className="date">
                    {chat.context?.booking?.bookDate ? (
                      <>· {formatBookingDate(chat.context.booking.bookDate)}</>
                    ) : (
                      ""
                    )}
                  </span>
                  <span className="date">
                    {chat.context?.booking?.bookHour ? (
                      <> · {chat.context?.booking?.bookHour}</>
                    ) : (
                      ""
                    )}
                  </span>
                </>
              )}
            </div>
            {chat.context.type === "collab" && (
              <div className="address">
                <span style={{ verticalAlign: "sub", marginRight: 5 }}>
                  <LocationIcon />
                </span>
                {chat.context?.venue?.address}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="content-container">
        <div ref={messagesContainerRef} className="messages-container">
          {chat.messages.map((message) => (
            <>
              {message.senderType === "foodie" && (
                <FoodieMessage key={message._id} {...message} />
              )}
              {message.senderType === "business" && (
                <BusinessMessage key={message._id} {...message} />
              )}
              {message.senderType === "mustard-on-behalf-of-business" && (
                <BusinessMessage key={message._id} {...message} />
              )}
              {message.senderType === "mustard" &&
                message.messageType === "text" && (
                  <MustardMessage key={message._id} {...message} />
                )}
              {message.senderType === "mustard" &&
                message.messageType === "foodie-action" && (
                  <ActionMessage key={message._id} {...message} />
                )}
              {![
                "foodie",
                "business",
                "mustard",
                "mustard-on-behalf-of-business",
              ].includes(message.senderType) && (
                <>
                  <div>Type: {message.senderType}</div>
                  <div className="card">{message.content}</div>
                </>
              )}
            </>
          ))}
        </div>

        <div
          className="input-container"
          style={{
            marginBottom: isMobile ? 84 : 0,
          }}
        >
          <div className="text-area-container">
            <Input.TextArea
              className="input"
              style={{ height: 50 }}
              placeholder="Reply"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  handleSendMessage();
                }
              }}
            />
            <div className="send-button-container">
              <Button
                type="primary"
                shape="round"
                size="large"
                disabled={isSendingMessage}
                onClick={handleSendMessage}
                loading={isSendingMessage}
              >
                <SendMessageIcon />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessengerChat;
