import { Input, Select } from "antd";
import { Country } from "types/country";

const countries: Country[] = require("./countries.json");

const prioritizeCountries = (
  countries: string[],
  countryOptions: { code: string; label: string; value: string }[]
) => {
  const prioritized = countries
    .map((countryCode) =>
      countryOptions.find((option) => option.code === countryCode)
    )
    .filter(Boolean);

  const uniqueOthers = new Map();
  prioritized.forEach(
    (option) => option && uniqueOthers.set(option.value, option)
  );

  countryOptions.forEach((option) => {
    if (!uniqueOthers.has(option.value)) {
      uniqueOthers.set(option.value, option);
    }
  });

  return [...Array.from(uniqueOthers.values())];
};

const getCountryCode = (country: Country) =>
  country.idd.suffixes.length > 1
    ? country.idd.root
    : `${country.idd.root}${country.idd.suffixes[0]}`;

const countryOptions = prioritizeCountries(
  [
    "US",
    //  "CA", "MX", "GB", "EE"
  ],
  countries.map((country) => ({
    value: getCountryCode(country),
    label: getCountryCode(country),
    code: country.cca2,
  }))
);

interface PhoneInputProps {
  value?: string;
  onChange?: (value: string) => void;
}

const PhoneInput: React.FC<PhoneInputProps> = ({ value = "+1", onChange }) => {
  const countryCode = value?.match(/^\+\d+/)?.[0] || "";
  const phoneNumber = value.replace(countryCode, "").trim();
  const handleCountryChange = (selectedOption: any) => {
    if (onChange) {
      if (phoneNumber) onChange(`${selectedOption} ${phoneNumber}`);
      else onChange(`${selectedOption}`);
    }
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value.trim();
    if (onChange) {
      if (newValue) onChange(`${countryCode} ${newValue}`);
      else onChange(`${countryCode}`);
    }
  };

  return (
    <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
      <Select
        value={countryCode}
        onChange={handleCountryChange}
        style={{ width: 110, fontSize: 16 }}
        showSearch
      >
        {countryOptions.map((option) => (
          <Select.Option
            key={`${option?.value}_${option?.code}`}
            value={option?.value}
          >
            <img
              src={`https://flagcdn.com/w40/${option?.code.toLowerCase()}.png`}
              alt={`${option?.code} flag`}
              width={16}
              height={12}
            />{" "}
            {option?.label}
          </Select.Option>
        ))}
      </Select>

      <Input
        placeholder="Phone number"
        value={phoneNumber}
        onChange={handlePhoneChange}
        style={{ flex: 1 }}
      />
    </div>
  );
};

export default PhoneInput;
