import { ReactComponent as CopyIcon } from "assets/images/copy-icon.svg";
import NavigationBar from "components/navigation-bar/NavigationBar";
import { onClickCopy } from "helpers/copy-helper";
import { useNavigate } from "react-router-dom";
const DevTools: React.FC = () => {
  const localStorageItems = Object.entries(localStorage);
  const sessionStorageItems = Object.entries(sessionStorage);
  const navigate = useNavigate();

  return (
    <div style={{ margin: "0 20px" }}>
      <NavigationBar onBack={() => navigate(-1)} />
      <h1>Dev Tools</h1>
      <div>
        <h3>local storage</h3>
        {localStorageItems.map(([key, value]) => (
          <div key={key} style={{ marginBottom: "10px" }}>
            <strong>{key}:</strong>
            <p style={{ overflow: "auto" }}>{value}</p>
            <CopyIcon onClick={() => onClickCopy(value)} />
          </div>
        ))}
        <h3>session storage</h3>
        {sessionStorageItems.map(([key, value]) => (
          <div key={key} style={{ marginBottom: "10px" }}>
            <strong>{key}:</strong>
            <p style={{ overflow: "auto" }}>{value}</p>
            <CopyIcon onClick={() => onClickCopy(value)} />
          </div>
        ))}
        <div style={{ marginBottom: "10px" }}>
          <strong>Version:</strong>
          <p style={{ overflow: "auto" }}> {process.env.REACT_APP_VERSION}</p>
          <CopyIcon
            onClick={() => onClickCopy(process.env.REACT_APP_VERSION || "")}
          />
        </div>
      </div>
    </div>
  );
};

export default DevTools;
