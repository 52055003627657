import { Button } from "antd";
import { useConstantDrawer } from "hooks/useDrawer";
import { useSelector } from "react-redux";
import { UserState } from "redux-context/user";
import CollabsFoodDrawerContent from "./CollabsFoodDrawerContent";
import CollabsCompDrawerContent from "./CollabsCompDrawerContent";
import { ReactComponent as StripeIcon } from "assets/images/stripe-icon.svg";

const CollabsConnectSocial: React.FC = () => {
  const { loggedUser } = useSelector((state: UserState) => state.user);
  const drawer1 = useConstantDrawer(
    <CollabsFoodDrawerContent />,
    "Connect social media",
    400
  );

  const drawer2 = useConstantDrawer(
    <CollabsCompDrawerContent />,
    "Connect social media",
    400
  );

  return (
    <div className="content">
      <div className="highlight">
        <h1 className="title">Hi {loggedUser?.name},</h1>
        <div
          style={{ display: "flex", justifyContent: "space-between", gap: 16 }}
        >
          🔒
          <span className="subtitle-18-semi-bold-inter" style={{ flex: 1 }}>
            Unlock Creator
          </span>
          <Button
            type="primary"
            size="small"
            shape="round"
            onClick={drawer1.openDrawer}
          >
            Unlock
          </Button>
        </div>
        <span className="caption-13-regular" style={{ marginLeft: 34 }}>
          get meals and access local collabs
        </span>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: 16,
            marginTop: 20,
          }}
        >
          <span style={{ position: "relative" }}>
            🔒
            <StripeIcon style={{ position: "absolute", bottom: -3, left: 7 }} />
          </span>
          <span className="subtitle-18-semi-bold-inter" style={{ flex: 1 }}>
            Unlock Business
          </span>
          <Button
            type="primary"
            size="small"
            shape="round"
            onClick={drawer2.openDrawer}
          >
            Unlock
          </Button>
        </div>
        <span className="caption-13-regular" style={{ marginLeft: 34 }}>
          get paid for your video content
        </span>
      </div>
      {drawer1.DrawerComponent}
      {drawer2.DrawerComponent}
    </div>
  );
};

export default CollabsConnectSocial;
