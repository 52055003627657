import { Button, Form, Input, message } from "antd";
import { useCallback, useState } from "react";
import { sendVerificationCode, signInPhone } from "services/SauceService";
import PhoneInput from "./PhoneInput";
import { autenticado, TOKEN } from "helpers/auth-functions";
enum Step {
  Phone = "phone",
  Code = "code",
}

type PhoneConnectionParam = {
  phone: string;
  verificationCode: string;
};

interface PhoneLoginProps {
  title?: string;
  onLogin: (response: any) => void;
  connectFunction?: (params: PhoneConnectionParam) => void;
}

const PhoneLogin: React.FC<PhoneLoginProps> = (props) => {
  const { title = "Phone verification", onLogin, connectFunction } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
  const phone = Form.useWatch<string>("phone", {
    form,
  });
  const [step, setStep] = useState<Step>(Step.Phone);
  const [timerToSendAgain, setTimerToSendAgain] = useState<number>();

  const startTimer = useCallback(() => {
    setTimerToSendAgain(60);
    const interval = setInterval(() => {
      setTimerToSendAgain((prev) => {
        if (prev === 0) {
          clearInterval(interval);
          return 0;
        }
        if (prev === undefined) {
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const signInPhoneWithCode = async ({
    phone,
    verificationCode,
  }: PhoneConnectionParam) => {
    const params = {
      phone: phone,
      code: verificationCode,
      internalAccessToken:
        (autenticado() && localStorage.getItem(TOKEN)) || undefined,
    };
    try {
      const response: any = await signInPhone(params);

      if (response?.accessToken) {
        onLogin(response);
      }
    } catch {
      message.error("Invalid code");
    }
    setLoading(false);
    return;
  };

  const onFinish = async () => {
    if (loading) return;
    setLoading(true);
    const { phone, verificationCode } = form.getFieldsValue(true);
    if (step === Step.Phone) {
      startTimer();
      const params = {
        contact: phone,
        type: "phone",
      };
      try {
        await sendVerificationCode(params);
        setStep(Step.Code);
      } catch {
        message.error("Invalid phone number");
      }
      setLoading(false);
      return;
    }
    if (step === Step.Code) {
      if (connectFunction) {
        const response = await connectFunction({ phone, verificationCode });
        onLogin(response);
      } else {
        signInPhoneWithCode({ phone, verificationCode });
      }
      setLoading(false);
      return;
    }
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <div
        style={{
          display: step === Step.Phone ? "block" : "none",
        }}
      >
        <span className="heading-1-32-bold" style={{ display: "block" }}>
          {title}
        </span>
        <span
          className="body-16-regular"
          style={{ display: "block", marginBottom: 20 }}
        >
          Receive only important notifications via SMS
        </span>
        <Form.Item
          name="phone"
          rules={[{ required: true, message: "Please input your phone!" }]}
        >
          <PhoneInput />
        </Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          style={{ width: "100%" }}
          loading={loading}
        >
          Continue
        </Button>
      </div>
      {step === Step.Code && (
        <div>
          <span className="heading-1-32-bold" style={{ display: "block" }}>
            Verification code
          </span>
          <span
            className="body-16-regular"
            style={{ display: "block", marginBottom: 20 }}
          >
            Code sent to {phone}
          </span>
          <div style={{ marginBottom: 24, height: 22 }}>
            {(timerToSendAgain || -1) > 0 && (
              <span className="timer-span">
                Resend the code in: 0:{timerToSendAgain}
              </span>
            )}
          </div>
          <Form.Item
            name="verificationCode"
            rules={[
              {
                required: true,
                message: "Please input the verification code!",
              },
            ]}
          >
            <Input.OTP length={6} style={{ width: "100%" }} mask />
          </Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{ width: "100%" }}
            loading={loading}
          >
            Send verification code
          </Button>
        </div>
      )}
    </Form>
  );
};

export default PhoneLogin;
