import NavigationBar from "components/navigation-bar/NavigationBar";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { hideMenu, showMenu } from "redux-context/page";
import {
  FacebookSDKLoader,
  fetchUserPermissions,
  fetchUserProfile,
  handleFacebookLogin,
} from "../../components/facebook-button/facebook-auth";
import { setToken } from "helpers/auth-functions";
import { getLoggedUserInfo, getLoggedUserSocials } from "redux-context/user";
import { connectMeta } from "services/SauceService";
import { message } from "antd";
import { updateTokenEvent } from "helpers/event-messages";

const FacebookDisclaimer: React.FC = () => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const onClickFacebookConnect = async () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ type: "facebook_auth" })
      );
      return;
    }

    try {
      const response = await handleFacebookLogin();
      if (response.status === "connected") {
        console.log(response);
        const accessToken = response.authResponse.accessToken;
        // setConnectSocialLoading(true);

        // await connectSocialMedia({
        //   businessId,
        //   accessToken,
        // });
        // setConnectSocialLoading(false);
        // message.success("Meta Businees connected successfully");

        const profile = await fetchUserProfile();
        const permissions = await fetchUserPermissions();

        try {
          const params = {
            metaToken: accessToken,
            permissions: permissions?.acceptedPermissions,
            declinedPermissions: permissions?.declinedPermissions,
            userID: profile.id,
          };
          const loginInfo: any = await connectMeta(params);
          updateTokenEvent(loginInfo.accessToken);
          setToken(loginInfo.accessToken);

          dispatch(getLoggedUserInfo());
          dispatch(getLoggedUserSocials());
          setTimeout(() => {
            navigate(`/meta-auth`, { replace: true });
          }, 2000);
        } catch (error: any) {
          message.error(error?.message || "Failed to connect Meta account");
        }
      } else {
        message.error("Failed to connect Meta account");
        console.log("User is not logged in with Meta");
      }
    } catch {
      message.error("Failed to connect Meta account");
      // setConnectSocialLoading(false);
    }
  };

  useEffect(() => {
    dispatch(hideMenu());
    return () => {
      dispatch(showMenu());
    };
  }, [dispatch]);

  return (
    <div className="page-full-height">
      <FacebookSDKLoader />
      <NavigationBar onBack={() => navigate(-1)} />
      <div
        style={{
          padding: "0 20px",
          flex: 1,
        }}
      >
        <h1 className="heading-1-32-bold" style={{ margin: 0 }}>
          Why do we need your Facebook account?
        </h1>
        <p className="body-16-regular-poppins">
          We will confirm one of the slots for collab with the business and
          notify you.
        </p>
        <ul className="welcome-card__steps-list">
          <li className="welcome-card__step-item">
            📜
            <div>
              <span className="body-16-semi-bold-inter">Meta's Rules</span>
              <p className="welcome-card__step-description body-16-regular">
                To access your Instagram data, your Instagram account must be
                connected to a Meta Business account. This is a Meta requirement
                for all businesses and creators.
              </p>
            </div>
          </li>
          <li className="welcome-card__step-item">
            ✔️
            <div>
              <span className="body-16-semi-bold-inter">Verification</span>
              <p className="welcome-card__step-description body-16-regular">
                Connecting your Meta Business account ensures that your
                Instagram profile is eligible and meets the professional account
                requirements.
              </p>
            </div>
          </li>
          <li className="welcome-card__step-item">
            🔗
            <div>
              <span className="body-16-semi-bold-inter">
                Streamlined Process
              </span>
              <p className="welcome-card__step-description body-16-regular">
                Once your Business Meta account is connected, we’ll guide you
                through linking your Instagram page as the final step.
              </p>
            </div>
          </li>
        </ul>
        <p className="body-16-regular-poppins">
          By following these steps, we can securely access your profile and
          provide you with the best collaboration opportunities
        </p>
      </div>
      <div
        style={{
          padding: 20,
        }}
      >
        <button
          className="button-facebook-color body-16-semi-bold-poppins"
          style={{ width: "100%", color: "#fff" }}
          onClick={onClickFacebookConnect}
        >
          Connect Facebook Account
        </button>
      </div>
    </div>
  );
};

export default FacebookDisclaimer;
