import { LeftOutlined } from "@ant-design/icons";
import { Button, Row, Typography } from "antd";
import { NavLink, useNavigate } from "react-router-dom";
import StripeConnected from "assets/images/stripe-connected.png";
import StripeDisconnected from "assets/images/stripe-disconnected.png";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import {
  createStripeLoggedUser,
  retrieveStripeWallet,
} from "services/SauceService";
import { useState } from "react";
import { openUrl } from "helpers/link-helper";
import { UserState } from "redux-context/user";

interface PaymentSettingsProps {
  // Add any props you need for the component here
}

const PaymentSettings: React.FC<PaymentSettingsProps> = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const { stripeConnected, stripeInfo, loggedUser } = useSelector(
    (state: UserState) => state.user
  );
  const connected = stripeConnected;

  const onClickGoToWallet = async () => {
    setLoading(true);
    const { stripeId } = stripeInfo;
    const response: any = await retrieveStripeWallet({ stripeId });
    openUrl(response.signInURL);
    setLoading(false);
  };

  const onClickCreateWallet = async () => {
    setLoading(true);
    const response: any = await createStripeLoggedUser();

    openUrl(response.url);
    setLoading(false);
  };

  const Connected = () => {
    return (
      <div className="container">
        <img src={StripeConnected} alt="Stripe connected" />
        <Typography.Title level={4}>
          Your Stripe Wallet connected
        </Typography.Title>
        <Button type="primary" onClick={onClickGoToWallet} loading={loading}>
          Go to my Wallet
        </Button>
      </div>
    );
  };

  const Disconnected = () => {
    return (
      <div className="container">
        <img
          src={StripeDisconnected}
          alt="Stripe disconnected"
          style={{ width: "50%" }}
        />
        <Typography.Title level={4}>Create your Stripe Wallet</Typography.Title>
        <Typography.Paragraph>
          You need to create a wallet in order to withdraw earned funds
        </Typography.Paragraph>
        {!loggedUser?.email && (
          <p style={{ margin: 0 }}>
            You need to add your email address before creating you Stripe
            Wallet, please go to{" "}
            <NavLink to="/profile/notifications">
              Notifications preferences
            </NavLink>
          </p>
        )}
        <Button
          type="primary"
          onClick={onClickCreateWallet}
          loading={loading}
          disabled={!loggedUser?.email}
        >
          Create wallet
        </Button>
      </div>
    );
  };

  return (
    <div className="payment-settings profile">
      <Row
        justify="space-between"
        style={{ alignItems: "center", marginTop: 20 }}
      >
        <div style={{ display: "flex" }}>
          <LeftOutlined
            style={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
          />
          <Typography.Title level={3} style={{ margin: "auto 20px" }}>
            Payment settings
          </Typography.Title>
        </div>
      </Row>
      <div style={{ marginTop: isMobile ? 40 : 80 }}>
        {connected ? <Connected /> : <Disconnected />}
      </div>
    </div>
  );
};

export default PaymentSettings;
