import { createSlice } from "@reduxjs/toolkit";

export type PageState = {
  page: {
    backgroundColor: string;
    menuVisibility: boolean;
  };
};

export const pageSlice: any = createSlice({
  name: "page",
  initialState: {
    backgroundColor: "",
    menuVisibility: true,
  },
  reducers: {
    setBackgroundColor: (state, action) => {
      state.backgroundColor = action.payload;
    },
    clearBackgroundColor: (state) => {
      state.backgroundColor = "";
    },
    showMenu: (state) => {
      state.menuVisibility = true;
    },
    hideMenu: (state) => {
      state.menuVisibility = false;
    },
  },
  extraReducers: (builder) => {},
});

export const { setBackgroundColor, clearBackgroundColor, showMenu, hideMenu } =
  pageSlice.actions;

export default pageSlice.reducer;
