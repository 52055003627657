import { ReactComponent as FacebookIcon } from "assets/images/facebook-icon.svg";
import { InstagramOutlined, TikTokOutlined } from "@ant-design/icons";
import { Divider } from "antd";
import { useNavigate } from "react-router-dom";
import { handleLoginInstagram } from "components/instagram-button/InstagramButton";
import { handleLoginTikTok } from "components/tiktok-buttom/TikTokButton";
import { useSelector } from "react-redux";
import { UserState } from "redux-context/user";
import { ConnectedSocial } from "interfaces/user-profile";

const ProfileAllSocialDrawer: React.FC = () => {
  const navigate = useNavigate();

  const { connectedSocials } = useSelector((state: UserState) => state.user);

  const { instagram, tiktok, meta } = connectedSocials?.reduce(
    (acc, social) => {
      acc[social.socialMediaName] = social;
      return acc;
    },
    {
      instagram: { socialMediaName: "instagram" },
      tiktok: { socialMediaName: "tiktok" },
      meta: { socialMediaName: "meta" },
    } as Record<string, ConnectedSocial>
  ) || { instagram: null, tiktok: null, meta: null };

  const onClickUnlockInstagram = () => {
    handleLoginInstagram();
  };

  const onClickUnlockTikTok = () => {
    handleLoginTikTok();
  };

  const onClickUnlockFacebook = () => {
    navigate("/facebook-disclaimer");
  };

  return (
    <div>
      <span className="body-16-regular">
        Apply for local collabs with complimentaty meals
      </span>
      <div className="platform-item" style={{ marginTop: 20 }}>
        <div
          className="platform-details"
          style={{
            display: "flex",
            alignItems: "center",
            gap: 12,
            justifyContent: "space-between",
          }}
          onClick={onClickUnlockFacebook}
        >
          <FacebookIcon style={{ alignSelf: "flex-start" }} />
          <div style={{ flex: 1 }}>
            <span className="subtitle-18-semi-bold-inter">
              Meta Business Account
            </span>
            {meta?.dateConnected ? (
              <p className="body-16-regular" style={{ margin: 0 }}>
                @{meta.userName}
              </p>
            ) : (
              <p className="body-16-regular" style={{ margin: 0 }}>
                from 1k followers
              </p>
            )}
          </div>
          {meta?.dateConnected ? (
            <span className="body-16-regular">Connected</span>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="arrow-icon"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
              width={12}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 5l7 7-7 7"
              />
            </svg>
          )}
        </div>
      </div>
      <Divider />
      <div className="platform-item">
        <div
          className="platform-details"
          style={{
            display: "flex",
            alignItems: "center",
            gap: 12,
            justifyContent: "space-between",
          }}
          onClick={onClickUnlockInstagram}
        >
          <InstagramOutlined
            style={{ color: "#EB355F", fontSize: 20, alignSelf: "flex-start" }}
          />
          <div style={{ flex: 1 }}>
            <span className="subtitle-18-semi-bold-inter">Instagram</span>
            {instagram?.dateConnected ? (
              <p className="body-16-regular" style={{ margin: 0 }}>
                @{instagram.userName}
              </p>
            ) : (
              <p className="body-16-regular" style={{ margin: 0 }}>
                from 1k followers
              </p>
            )}
          </div>
          {instagram?.dateConnected ? (
            <span className="body-16-regular">Connected</span>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="arrow-icon"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
              width={12}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 5l7 7-7 7"
              />
            </svg>
          )}
        </div>
      </div>
      <Divider />
      <div className="platform-item">
        <div
          className="platform-details"
          style={{
            display: "flex",
            alignItems: "center",
            gap: 12,
            justifyContent: "space-between",
          }}
          onClick={onClickUnlockTikTok}
        >
          <TikTokOutlined
            style={{ color: "#EB355F", fontSize: 20, alignSelf: "flex-start" }}
          />
          <div style={{ flex: 1 }}>
            <span className="subtitle-18-semi-bold-inter">TikTok</span>
            {tiktok?.dateConnected ? (
              <p className="body-16-regular" style={{ margin: 0 }}>
                @{tiktok.userName}
              </p>
            ) : (
              <p className="body-16-regular" style={{ margin: 0 }}>
                from 1k followers
              </p>
            )}
          </div>
          {tiktok?.dateConnected ? (
            <span className="body-16-regular">Connected</span>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="arrow-icon"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
              width={12}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 5l7 7-7 7"
              />
            </svg>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfileAllSocialDrawer;
