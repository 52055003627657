import { autenticado, logout } from "helpers/auth-functions";
import { ProfileStatus } from "interfaces/user-profile";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { UserState } from "redux-context/user";

const AuthenticationGuard: React.FC<any> = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { loggedUser, loading } = useSelector((state: UserState) => state.user);
  // const searchParams = new URLSearchParams(location.search);
  // const businessId = searchParams.get("businessId");
  // if (businessId && businessId !== null) {
  //   localStorage.setItem("businessId", businessId);
  //   searchParams.delete("businessId");
  //   window.history.replaceState({}, "", location.pathname);
  // }
  const isAuthenticated = autenticado();

  useEffect(() => {
    const { profileStatus } = loggedUser;
    if (profileStatus === ProfileStatus.SocialPending) {
      navigate("/profile/social-media");
      return;
    }
    if (profileStatus === ProfileStatus.ChooseSocialMedia) {
      navigate("/profile/page-selection");
      return;
    }
    if (profileStatus === ProfileStatus.LocationPending) {
      navigate("/profile/coverage");
      return;
    }

    if (profileStatus === ProfileStatus.MissingPhone) {
      navigate("/phone-onboarding");
      return;
    }

    if (profileStatus === ProfileStatus.MissingName) {
      navigate("/welcome-name");
      return;
    }

    if (profileStatus === ProfileStatus.OutdatedAppVersion) {
      navigate("/update-app");
      return;
    }

    if (
      !loading &&
      !profileStatus &&
      profileStatus !== ProfileStatus.Active &&
      !loggedUser.aborted
    ) {
      logout();
      return;
    }
  }, [loggedUser, loading, navigate, location.pathname]);

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default AuthenticationGuard;
