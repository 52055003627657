import { ReactComponent as LeftIcon } from "assets/images/left-arrow-icon.svg";
import { ReactComponent as Logo } from "assets/images/logo.svg";
import { isMobile } from "react-device-detect";
import "./NavigationBar.scss";

const NavigationBar: React.FC<{ onBack: () => void; hidden?: boolean }> = ({
  onBack,
  hidden,
}) => {
  if (hidden) {
    return <div className="navigation-bar hidden" />;
  }
  return (
    <div className="navigation-bar">
      <div
        className="align-justify-center"
        style={{ gap: 4, position: isMobile ? "relative" : "absolute" }}
      >
        <div className="back-button">
          <LeftIcon onClick={onBack} />
        </div>
        Back
      </div>
      {!isMobile && <Logo style={{ flex: 1 }} />}
    </div>
  );
};

export default NavigationBar;
