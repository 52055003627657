import { Button, Drawer } from "antd";
import { useConstantDrawer } from "hooks/useDrawer";
import React, { useState } from "react";
import { isDesktop } from "react-device-detect";
import CollabsFoodDrawerContent from "./CollabsFoodDrawerContent";
import CollabsCompDrawerContent from "./CollabsCompDrawerContent";

const CollabsConnectSocialDrawer: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false);

  const drawer1 = useConstantDrawer(
    <CollabsFoodDrawerContent />,
    "Connect social media",
    400
  );

  const drawer2 = useConstantDrawer(
    <CollabsCompDrawerContent />,
    "Connect social media",
    400
  );

  return (
    <>
      <div
        className="collabs-social"
        onClick={() => setOpen(true)}
        style={{
          backgroundColor: "#E5EBFF",
          border: "1px solid #1f3fff4d",
          borderRadius: 8,
          padding: 16,
        }}
      >
        <div
          style={{ display: "flex", justifyContent: "space-between", gap: 16 }}
        >
          🔒
          <span className="body-16-regular" style={{ flex: 1 }}>
            <b>Connect your social</b> media to unlock collabs near you.
          </span>
          {drawer1.DrawerComponent}
          {drawer2.DrawerComponent}
        </div>
        <Button
          type="primary"
          size="small"
          shape="round"
          style={{
            backgroundColor: "transparent",
            marginLeft: 32,
            marginTop: 12,
          }}
          // onClick={drawer1.openDrawer}
        >
          Unlock collabs
        </Button>
      </div>
      <Drawer
        placement="bottom"
        rootClassName="drawer-rating"
        title="Rating"
        onClose={() => setOpen(false)}
        open={open}
        // style={{ width: 400 }}
        classNames={{
          header: "collabs-drawer-header",
          body: "collabs-drawer-body",
        }}
        styles={{
          header: {
            border: "none",
            // width: 400,
          },
          // content: {
          //   alignItems: "center",
          // },
          body: {
            marginLeft: isDesktop ? 265 : 0,
            justifyContent: "center",
            display: "flex",
          },
          // wrapper: {
          //   height: 250,
          // },
        }}
        destroyOnClose
      >
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: 20,
            }}
          >
            🔒
            <span className="subtitle-18-semi-bold-inter" style={{ flex: 1 }}>
              Food compensation
            </span>
            <Button
              type="primary"
              size="small"
              shape="round"
              onClick={() => {
                setOpen(false);
                setTimeout(() => drawer1.openDrawer(), 300);
              }}
            >
              Unlock
            </Button>
          </div>
          <span className="caption-13-regular" style={{ marginLeft: 32 }}>
            from 2K followers on Instagram or TikTok
          </span>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: 16,
              marginTop: 30,
            }}
          >
            🔒
            <span className="subtitle-18-semi-bold-inter" style={{ flex: 1 }}>
              Food & cash compensation{" "}
            </span>
            <Button
              type="primary"
              size="small"
              shape="round"
              onClick={() => {
                setOpen(false);
                setTimeout(() => drawer2.openDrawer(), 300);
              }}
            >
              Unlock
            </Button>
          </div>
          <span className="caption-13-regular" style={{ marginLeft: 32 }}>
            from 10K followers on Instagram or TikTok
          </span>
        </div>
      </Drawer>
    </>
  );
};

export default CollabsConnectSocialDrawer;
