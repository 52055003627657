import { Button, Col, message, Row, Spin, Typography } from "antd";
import { isDesktop, isTablet } from "react-device-detect";
import EmptyItems from "assets/images/empty-itens.png";
// import { RightOutlined } from "@ant-design/icons";
import { useCallback, useEffect, useState } from "react";
import "./Earnings.scss";
import {
  getStripeBalance,
  getStripeStatements,
  stripeCreatePayout,
} from "services/SauceService";
import dayjs from "dayjs";
import { LoadingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { UserState } from "redux-context/user";
import CollabsConnectSocialDrawer from "pages/connect-social/CollabsConnectSocialDrawer";

enum EarningType {
  Earning = "earning",
  Payout = "payout",
  Transfer = "transfer",
}

interface Earning {
  amount: number;
  currency: string;
  date: string;
  description: string;
  type: EarningType;
}

interface Balance {
  amount: number;
  currency: string;
}

const Earnings: React.FC = () => {
  const [balance, setBalance] = useState<Balance>({
    amount: 0,
    currency: "USD",
  });

  const navigate = useNavigate();
  const [earnings, setEarnings] = useState<Earning[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { stripeConnected, socialConnected } = useSelector(
    (state: UserState) => state.user
  );

  const hasWallet = stripeConnected && false;
  const getInformations = useCallback(async () => {
    const balance: any = await getStripeBalance();

    if (!balance || balance?.length === 0) return;
    setBalance(balance[0]);
    const statements: any = await getStripeStatements();
    setEarnings(statements);
  }, []);

  const onClickWithdraw = async () => {
    try {
      setLoading(true);
      await stripeCreatePayout();
      message.success("Your payment is being processed");
      //TODO colocar tela de sucesso
    } catch {
      message.error("Error processing payment");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getInformations();
  }, [getInformations]);

  if (hasWallet) {
    return (
      <div className="earnings page-full-height">
        <div className="box-create-wallet">
          <span className="sub-title">
            Please create your Stripe Account to see your balance and
            transactions
          </span>
          <Button type="primary" shape="round" size="large">
            Create Wallet
          </Button>
        </div>
      </div>
    );
  }

  return (
    <Spin
      indicator={<LoadingOutlined style={{ fontSize: 48 }} />}
      spinning={loading}
    >
      <div className="earnings">
        {!socialConnected && (
          <div style={{ padding: 12, marginBottom: 12 }}>
            <CollabsConnectSocialDrawer />
          </div>
        )}
        <Row className="header">
          <Col xs={12}>
            <span className="label">You made a total of:</span>
            <span className="value">${balance.amount}</span>
          </Col>
          <Col
            xs={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Button
              type="primary"
              shape="round"
              size="large"
              disabled={!balance?.amount}
              onClick={onClickWithdraw}
            >
              Withdraw
            </Button>
          </Col>
        </Row>
        <div
          className="history"
          style={{ paddingBottom: isDesktop || isTablet ? 20 : 100 }}
        >
          <div className="header">
            <Typography.Title level={3}>History</Typography.Title>
            <span>Earned</span>
          </div>
          {earnings.length > 0 ? (
            <div className="earnings-list">
              {earnings.map((earning, index) => (
                <Row
                  key={`${earning.currency}_${index}`}
                  className="earning"
                  // onClick={() => onClickOpenEarning(earning)}
                >
                  <div style={{ flex: 1 }}>
                    <span className="name">{earning.description}</span>
                    {/* <span className="value ellipsis" style={{ width: 300 }}>
                    {earning.description}
                  </span> */}
                  </div>
                  <div style={{ textAlign: "end", flex: 1 }}>
                    <span className="amount">
                      {earning.type === EarningType.Payout ? "-" : ""}
                      {Math.abs(earning.amount).toLocaleString("en-US", {
                        style: "currency",
                        currency: earning.currency,
                      })}
                    </span>
                    <span className="value">
                      {dayjs.utc(earning.date).format("MMMM DD, hh:mm A")}
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "0 6px",
                    }}
                  >
                    {/* <RightOutlined /> */}
                  </div>
                </Row>
              ))}
            </div>
          ) : (
            <div className="content">
              <img src={EmptyItems} alt="" style={{ width: "50%" }} />
              <span className="title">No completed collabs yet</span>
              <span className="sub-title">
                The history of your earnings will be here
              </span>
              <Button
                type="primary"
                style={{ marginTop: 32 }}
                onClick={() => navigate("/find-collabs")}
              >
                Find Collab
              </Button>
            </div>
          )}
        </div>

        {/* <Drawer
        title="Details"
        placement="bottom"
        closable={true}
        onClose={() => setShowEarning(false)}
        open={showEarning}
        rootClassName={`drawer-earning ${
          selectedEarning?.type === EarningType.Earning ? "earning" : "withdraw"
        }`}
        styles={{
          content: {
            marginLeft: isDesktop || isTablet ? 265 : 0,
          },
        }}
        // getContainer={() => document.querySelector(".earnings") as HTMLElement}
      >
        <h1>
          <span className="amount">
            {selectedEarning.type === EarningType.Withdraw ? "-" : ""}$
            {selectedEarning.value}
          </span>
        </h1>
        <span className="value">{selectedEarning.date}</span>
        <span className="name">{selectedEarning.name}</span>
        <span className="value ellipsis" style={{ width: 300 }}>
          {selectedEarning.description}
        </span>
        <Button type="primary" shape="round" size="large">
          See collab
        </Button>
      </Drawer> */}
        {/* {showEarning && (
        <div
          className={`earnings-drawer ${
            selectedEarning?.type === EarningType.Earning
              ? "earning"
              : "withdraw"
          }`}
          style={{
            width: isDesktop || isTablet ? 550 : 398,
          }}
        >
          <div className="header">
            <span className="name">{selectedEarning?.name}</span>
            <span className="value">${selectedEarning?.value}</span>
            <CloseOutlined onClick={() => setShowEarning(false)} />
          </div>
        </div>
      )} */}
      </div>
    </Spin>
  );
};

export default Earnings;
