import { Avatar, Button } from "antd";
import { Chat } from "types/chat";
import { getRelativeTimeString } from "../utils";
import { useState } from "react";
import "./ActionMessage.scss";
import { callChatAction } from "services/SauceService";
import { useNavigate } from "react-router-dom";

export const ActionMessage = ({
  content,
  _id,
  createdAt,
  sender,
  actionsParameters,
  hideActions = false,
}: Chat["messages"][number]) => {
  const navigate = useNavigate();
  const [isLoadingRejectAction, setIsLoadingRejectAction] = useState(false);
  const [isLoadingConfirmAction, setIsLoadingConfirmAction] = useState(false);
  const [isActionSuccess, setIsActionSuccess] = useState(false);
  const [isLoadingRedirect, setIsLoadingRedirect] = useState(false);
  const [confirmationAction, setConfirmationAction] = useState<{
    type: "confirm" | "reject";
    action: string;
    bookingId: string;
    description: string;
  } | null>(null);

  const handleActionClick = (type: "confirm" | "reject") => {
    if (type === "confirm" && actionsParameters?.confirmAction) {
      setConfirmationAction({
        type,
        action: actionsParameters.confirmAction.action,
        bookingId: actionsParameters.confirmAction.id,
        description: actionsParameters.confirmAction.description,
      });
    } else if (type === "reject" && actionsParameters?.rejectAction) {
      setConfirmationAction({
        type,
        action: actionsParameters.rejectAction.action,
        bookingId: actionsParameters.rejectAction.id,
        description: actionsParameters.rejectAction.description,
      });
    }
  };

  const callAction = async ({
    action,
    bookingId,
  }: {
    action: string;
    bookingId: string;
  }) => {
    try {
      setConfirmationAction(null);
      if (action.includes("confirm")) {
        setIsLoadingConfirmAction(true);
      } else if (action.includes("reject")) {
        setIsLoadingRejectAction(true);
      }
      await callChatAction({
        action,
        bookingId: bookingId,
        messageId: _id,
      });
      setIsActionSuccess(true);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingConfirmAction(false);
      setIsLoadingRejectAction(false);
      setConfirmationAction(null);
    }
  };

  return (
    <div>
      <div className="action-message__container">
        <div>
          <Avatar src={sender.avatarUrl} />
        </div>
        <div className="message">
          {content}

          <div className="action-message__actions-container">
            {actionsParameters && (
              <div className="action-message__actions">
                {!hideActions &&
                  !isActionSuccess &&
                  actionsParameters.confirmAction && (
                    <Button
                      loading={isLoadingConfirmAction}
                      disabled={isLoadingConfirmAction || isLoadingRejectAction}
                      style={{
                        borderColor: "#68b144",
                        color: "green",
                      }}
                      onClick={() => handleActionClick("confirm")}
                    >
                      {actionsParameters.confirmAction.description}
                    </Button>
                  )}
                {!hideActions &&
                  !isActionSuccess &&
                  actionsParameters.rejectAction && (
                    <Button
                      loading={isLoadingRejectAction}
                      disabled={isLoadingRejectAction || isLoadingConfirmAction}
                      style={{
                        borderColor: "#ff4d4f",
                        color: "#ff4d4f",
                      }}
                      onClick={() => handleActionClick("reject")}
                    >
                      {actionsParameters.rejectAction.description}
                    </Button>
                  )}
                {hideActions && isActionSuccess && <Button disabled>✅</Button>}
                {actionsParameters.redirect && (
                  <Button
                    loading={isLoadingRedirect}
                    onClick={() => {
                      if (actionsParameters?.redirect?.url) {
                        setIsLoadingRedirect(true);
                        navigate(actionsParameters?.redirect?.url);
                      } else {
                        console.error(
                          "No URL provided for redirect",
                          actionsParameters?.redirect
                        );
                      }
                    }}
                  >
                    {actionsParameters?.redirect?.description}
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="action-message__timestamp">
        {getRelativeTimeString(new Date(createdAt))}
      </div>
      {confirmationAction && (
        <div className="action-message__confirmation-overlay">
          <div className="action-message__confirmation-content">
            <div className="action-message__confirmation-content-text">
              Are you sure you want to{" "}
              {confirmationAction.description.toLowerCase()}?
            </div>
            <div className="action-message__confirmation-content-buttons">
              <Button onClick={() => setConfirmationAction(null)}>
                Cancel
              </Button>
              <Button
                type="primary"
                danger={confirmationAction.type === "reject"}
                style={
                  confirmationAction.type === "confirm"
                    ? {
                        backgroundColor: "#68b144",
                        color: "white",
                      }
                    : {}
                }
                onClick={() => {
                  callAction({
                    action: confirmationAction.action,
                    bookingId: confirmationAction.bookingId,
                  });
                }}
              >
                Confirm
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
