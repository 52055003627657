import { Layout } from "antd";
import { ReactComponent as CollabsIcon } from "assets/images/collabs-icon.svg";
import { ReactComponent as FindCollabsIcon } from "assets/images/find-icon.svg";
import { ReactComponent as EarningsIcon } from "assets/images/earnings-icon.svg";
import { ReactComponent as ProfileIcon } from "assets/images/profile-icon.svg";
import { ReactComponent as MessengerIcon } from "assets/images/messenger-icon.svg";
import { useLocation, useNavigate } from "react-router-dom";
// import { DownOutlined } from "@ant-design/icons";
// import { Footer } from "antd/es/layout/layout";
// import { logout } from "helpers/auth-functions";
import "./Layout.scss";
import { ProfileStatus, UserProfile } from "interfaces/user-profile";
import { useSelector } from "react-redux";
import { PageState } from "redux-context/page";
const { Content } = Layout;

const MobileLayout: React.FC<any> = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { loggedUser }: { loggedUser: UserProfile } = useSelector(
    (state: any) => state.user
  );
  const { menuVisibility } = useSelector((state: PageState) => state.page);
  // const { business } = useSelector((state: any) => state.business);

  // const onClickLogout = () => {
  //   // logout();
  //   navigate("/login");
  // };

  const getSelectedMenuByPath = (menu: string) => {
    const path = location.pathname;
    if ((path === "/" && menu === "collabs") || (path === "/" && menu === "/"))
      return "selected";

    if (path === "/") return "";

    if (path.startsWith(menu) && menu !== "/") {
      return "selected";
    }

    return "";
  };

  return (
    <Layout style={{ alignItems: "center" }}>
      <Layout
        style={{
          width: "100%",
          borderTop: 0,
          borderBottom: 0,
          display: "flex",
          alignItems: "center",
        }}
        className="layout"
      >
        <Content style={{ width: "100%", maxWidth: 500 }}>{children}</Content>
        {loggedUser.profileStatus === ProfileStatus.Active && (
          <div
            className="footer"
            style={{ display: menuVisibility ? "flex" : "none" }}
          >
            <span
              className={`menu ${getSelectedMenuByPath("/")}`}
              onClick={() => navigate("/")}
            >
              <CollabsIcon />
              Collabs
            </span>
            <span
              className={`menu ${getSelectedMenuByPath("/find-collabs")}`}
              onClick={() => navigate("/find-collabs")}
            >
              <FindCollabsIcon />
              Find Collabs
            </span>
            <span
              className={`menu ${getSelectedMenuByPath("/messenger")}`}
              onClick={() => navigate("/messenger")}
            >
              <MessengerIcon />
              Messenger
            </span>
            <span
              className={`menu ${getSelectedMenuByPath("/earnings")}`}
              onClick={() => navigate("/earnings")}
            >
              <EarningsIcon />
              Earnings
            </span>
            <span
              className={`menu ${getSelectedMenuByPath("/profile")}`}
              onClick={() => navigate("/profile")}
            >
              <ProfileIcon />
              Profile
            </span>
          </div>
        )}
      </Layout>
    </Layout>
  );
};

export default MobileLayout;
