import { LeftOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Form, Row, Typography } from "antd";
import { ReactComponent as EditIcon } from "assets/images/edit-icon.svg";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { UserProfile } from "interfaces/user-profile";
import { debounce } from "lodash";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getLoggedUserInfo } from "redux-context/user";
import { updateUserInfoNotification } from "services/SauceService";

const NotificationsPreferences: React.FC = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const stopNotification = Form.useWatch<boolean>("stopSendingCommunication", {
    form,
  });
  const { loggedUser }: { loggedUser: UserProfile } = useSelector(
    (state: any) => state.user
  );

  const debouncedSave = useRef(
    debounce(async () => {
      const { email, phone, ...notificationPreferences } =
        form.getFieldsValue(true);
      await updateUserInfoNotification({
        notificationPreferences,
        email,
        phone,
      });
      // message.success("Notification preferences updated successfully");
      dispatch(getLoggedUserInfo());
    }, 2000)
  ).current;

  const onChangeStopCommunication = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      form.setFieldsValue({
        keepPostedOverEmail: false,
        keepPostedOverText: false,
        keepPostedOverNotifications: false,
      });
    } else {
      form.setFieldsValue({
        keepPostedOverEmail: true,
        keepPostedOverText: true,
        keepPostedOverNotifications: true,
      });
    }
  };

  useEffect(() => {
    form.resetFields();
  }, [loggedUser, form]);

  return (
    <div className="notifications profile">
      <Row
        justify="space-between"
        style={{ alignItems: "center", marginTop: 20 }}
      >
        <div style={{ display: "flex" }}>
          <LeftOutlined
            style={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
          />
          <Typography.Title level={3} style={{ margin: "auto 20px" }}>
            Notifications
          </Typography.Title>
        </div>
      </Row>
      <Form
        className="notifications"
        form={form}
        initialValues={{
          ...loggedUser.notificationPreferences,
          email: loggedUser.email,
          phone: loggedUser.phone,
        }}
        onChange={() => {
          debouncedSave();
        }}
      >
        <span className="sub-title">Send me collabs that offer:</span>
        <Row>
          <Col xs={24}>
            <div className="checkbox-container">
              <Form.Item noStyle name="foodComp" valuePropName="checked">
                <Checkbox />
              </Form.Item>
              Food compensation
            </div>
          </Col>

          <Col xs={24}>
            <div className="checkbox-container">
              <Form.Item noStyle name="cashComp" valuePropName="checked">
                <Checkbox />
              </Form.Item>
              Payment only
            </div>
          </Col>

          <Col xs={24}>
            <div className="checkbox-container">
              <Form.Item noStyle name="affiliateComp" valuePropName="checked">
                <Checkbox />
              </Form.Item>
              Affiliate fees
            </div>
          </Col>
        </Row>

        <span className="sub-title">Keep me posted over: </span>
        <Row>
          <Col xs={8}>
            <div className="checkbox-container">
              <Form.Item
                noStyle
                name="keepPostedOverEmail"
                valuePropName="checked"
              >
                <Checkbox disabled={stopNotification} />
              </Form.Item>
              Email
            </div>
          </Col>
          <Col xs={16}>
            {loggedUser?.email ? (
              <div className="value-field">
                <span>{loggedUser?.email}</span>
                <EditIcon
                  onClick={() => navigate("/profile/add-contact/email")}
                  style={{ cursor: "pointer", marginLeft: 10, width: 16 }}
                />
              </div>
            ) : (
              <Button
                type="primary"
                size="small"
                shape="round"
                onClick={() => navigate("/profile/add-contact/email")}
              >
                Add Email
              </Button>
            )}
          </Col>
        </Row>
        <Row>
          <Col xs={8}>
            <div className="checkbox-container">
              <Form.Item
                noStyle
                name="keepPostedOverText"
                valuePropName="checked"
              >
                <Checkbox disabled={stopNotification} />
              </Form.Item>
              Phone
            </div>
          </Col>
          <Col xs={16}>
            {loggedUser?.phone ? (
              <div className="value-field">
                <span>{loggedUser?.phone}</span>
                <EditIcon
                  onClick={() => navigate("/profile/add-contact/phone")}
                  style={{ cursor: "pointer", marginLeft: 10, width: 16 }}
                />
              </div>
            ) : (
              <Button
                type="primary"
                size="small"
                shape="round"
                onClick={() => navigate("/profile/add-contact/phone")}
              >
                Add Phone
              </Button>
            )}
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <div className="checkbox-container">
              <Form.Item
                noStyle
                name="keepPostedOverNotifications"
                valuePropName="checked"
              >
                <Checkbox disabled={stopNotification} />
              </Form.Item>
              Push notifications
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <div className="checkbox-container">
              <Form.Item
                noStyle
                name="stopSendingCommunication"
                valuePropName="checked"
              >
                <Checkbox onChange={onChangeStopCommunication} />
              </Form.Item>
              Not interested in any communication from Mustard 😞
            </div>
            <span style={{ color: "red" }}>
              Please note that you will need to reapply to our foodie network
              and meet the qualifications again to start receiving
              collaborations.
            </span>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default NotificationsPreferences;
