import { Outlet, createBrowserRouter } from "react-router-dom";
import App from "./App";
import DefaultLayout from "theme/Layout";
import NotFound from "pages/not-found";
import Collabs from "pages/collabs";
import FindCollabs from "pages/find-collabs";
import Earnings from "pages/earnings";
import Profile from "pages/profile";
import Notification from "pages/collabs/Notification";
import History from "pages/collabs/History";
// import { motion, AnimatePresence } from "framer-motion";
import CollabDetail from "pages/collabs/CollabDetail";
import { collabLoader } from "pages/collabs/collabLoader";
import BookCollab from "pages/book/BookCollab";
import BookCollabConfirmation from "pages/book/BookCollabConfirmation";
import BookCollabSuccess from "pages/book/BookCollabSuccess";
import BookCollabFailed from "pages/book/BookCollabFailed";
import DietaryRestrictions from "pages/dietary-restrictions/DietaryRestrictions";
import AddressDelivery from "pages/address-delivery/AddressDelivery";
import { isMobile } from "react-device-detect";
import MobileLayout from "theme/MobileLayout";
import { homeLoader } from "pages/collabs/homeLoader";
import LoginLayout from "theme/LoginLayout";
import Login from "pages/login";
import SocialMedia from "pages/profile/SocialMedia";
import BestCoverage from "pages/profile/BestCoverage";
import BestCategories from "pages/profile/BestCategories";
import NotificationsPreferences from "pages/profile/NotificationsPrefences";
import PaymentSettings from "pages/profile/PaymentSettings";
import PersonalInfo from "pages/profile/PersonalInfo";
import MagicLink from "pages/login/MagicLink";
import Error from "pages/error/Error";
import Certificate from "pages/certificate/Certificate";
import Checkin from "pages/checkin/Checkin";
import Welcome from "pages/checkin/Welcome";
import Confirm from "pages/confirm/Confirm";
import Confirmed from "pages/confirm/Confirmed";
import AuthenticationGuard from "components/authentication-guard/AuthenticationGuard";
import SigninApp from "pages/login/SigninApp";
import { historyLoader } from "pages/collabs/historyloader";
import BookDetail from "pages/book/BookDetail";
import { bookLoader } from "pages/book/bookLoader";
import BookSubmitContent from "pages/book/BookSubmitContent";
import ContentSumitted from "pages/book/ContentSubmitted";
import BookCancel from "pages/book/BookCancel";
import BookCancelled from "pages/book/BookCancelled";
import PageSelection from "pages/profile/PageSelection";
import { instagramPageLoader } from "pages/profile/intagramPageLoader";
import SubmitContentRoute from "pages/book/SubmitContentRoute";
import InstagramAuth from "pages/instagram-auth/InstagramAuth";
import DevTools from "pages/dev-tools/DevTools";
import TikTokAuth from "pages/tiktok-auth/TikTokAuth";
import BookReschedule from "pages/book/BookReschedule";
import Messenger from "pages/messenger";
import MessengerChat from "pages/messenger/Chat";
import WelcomeName from "pages/welcome-name/WelcomeName";
import AppleAuth from "pages/apple-auth/AppleAuth";
import PhoneOnboarding from "pages/phone-onboarding/PhoneOnboarding";
import OutdatedApp from "pages/outdated-app/OutdatedApp";
import CreatorPledge from "pages/connect-social/CreatorPledge";
import FacebookDisclaimer from "pages/meta-auth/FacebookDisclaimer";
import MetaAuth from "pages/meta-auth/MetaAuth";
import SigninSauce from "pages/login/SigninSauce";
import ConnectContact from "pages/profile/ConnectContact";

// const pageTransitionVariants = {
//   initial: {
//     opacity: 0,
//     x: 300,
//   },
//   animate: {
//     opacity: 1,
//     x: 0,
//   },
//   exit: {
//     opacity: 0,
//     x: -300,
//   },
// };

// const reversePageTransitionVariants = {
//   initial: {
//     opacity: 0,
//     x: -300,
//   },
//   animate: {
//     opacity: 1,
//     x: 0,
//   },
//   exit: {
//     opacity: 0,
//     x: 300,
//   },
// };

// const LayoutWithTransition = () => {
//   const location = useLocation();
//   return (
//     <AnimatePresence mode="wait">
//       <motion.div
//         key={location.pathname}
//         variants={pageTransitionVariants}
//         initial="initial"
//         animate="animate"
//         exit="exit"
//         transition={{ type: "tween", duration: 0.3 }}
//         style={{
//           width: "100%",
//           minHeight: "calc(100vh - 86px)",
//           display: "grid",
//         }}
//       >
//         <Outlet />
//       </motion.div>
//     </AnimatePresence>
//   );
// };

const router = createBrowserRouter([
  {
    path: "/",
    element: isMobile ? (
      <MobileLayout>
        <App />
      </MobileLayout>
    ) : (
      <DefaultLayout>
        <App />
      </DefaultLayout>
    ),
    errorElement: isMobile ? (
      <MobileLayout>
        <Error />
      </MobileLayout>
    ) : (
      <DefaultLayout>
        <Error />
      </DefaultLayout>
    ),
    children: [
      {
        element: (
          <AuthenticationGuard>
            <Outlet />
          </AuthenticationGuard>
        ),
        children: [
          {
            path: "/",
            element: <Collabs />,
            loader: homeLoader,
          },
          {
            path: "/find-collabs",
            element: <FindCollabs />,
            // loader: collabsLoader,
          },
          {
            path: "/collabs/:id",
            element: <CollabDetail />,
            loader: collabLoader,
          },
          {
            path: "/collab/book/:id",
            element: <BookCollab />,
            loader: collabLoader,
          },
          {
            path: "/book/collab-confirmation",
            element: <BookCollabConfirmation />,
          },
          {
            path: "/book/collab-success",
            element: <BookCollabSuccess />,
          },
          {
            path: "/book/collab-failed",
            element: <BookCollabFailed />,
          },
          {
            path: "/dietary-restrictions",
            element: <DietaryRestrictions />,
          },
          {
            path: "/book/address-delivery",
            element: <AddressDelivery />,
          },
          {
            path: "/book/detail/:id",
            element: <BookDetail />,
            loader: bookLoader,
          },
          {
            path: "/book/submit-content",
            element: <BookSubmitContent />,
          },
          {
            path: "/book/cancel",
            element: <BookCancel />,
          },
          {
            path: "/book/cancelled",
            element: <BookCancelled />,
          },
          {
            path: "/book/reschedule",
            element: <BookReschedule />,
          },
          {
            path: "/earnings",
            element: <Earnings />,
            // loader: collabLoader,
          },
          {
            path: "/messenger",
            element: <Messenger />,
          },
          {
            path: "/messenger/:id",
            element: <MessengerChat />,
          },
          {
            path: "/profile",
            element: <Profile />,
          },
          {
            path: "/profile/personal-info",
            element: <PersonalInfo />,
          },
          {
            path: "/profile/social-media",
            element: <SocialMedia />,
          },
          {
            path: "/profile/coverage",
            element: <BestCoverage />,
          },
          {
            path: "/profile/categories",
            element: <BestCategories />,
          },
          {
            path: "/profile/notifications",
            element: <NotificationsPreferences />,
          },
          {
            path: "/profile/payment-settings",
            element: <PaymentSettings />,
          },
          {
            path: "/profile/page-selection",
            element: <PageSelection />,
            loader: instagramPageLoader,
          },
          {
            path: "/profile/add-contact/:type",
            element: <ConnectContact />,
          },
          {
            path: "/notifications",
            element: <Notification />,
            // loader: applicationsLoader
          },
          {
            path: "/history",
            element: <History />,
            loader: historyLoader,
          },
          {
            path: "/certificate",
            element: <Certificate />,
          },
          {
            path: "/checkin",
            element: <Checkin />,
          },
          {
            path: "/confirm",
            element: <Confirm />,
          },
          {
            path: "/welcome",
            element: <Welcome />,
          },
          {
            path: "/confirmed",
            element: <Confirmed />,
          },
          {
            path: "/welcome-name",
            element: <WelcomeName />,
          },
          {
            path: "/phone-onboarding",
            element: <PhoneOnboarding />,
          },
          {
            path: "/creator-pledge",
            element: <CreatorPledge />,
          },
          {
            path: "/facebook-disclaimer",
            element: <FacebookDisclaimer />,
          },

          // { path: "/collabs/new", element: <CollabEdit /> },
          // {
          //   path: "/influencers",
          //   element: <Influencerr />,
          //   loader: influencersLoader,
          // },
          // { path: "/messages", element: <Messages /> },
          // { path: "/settings", element: <Settings /> },
        ],
      },
    ],
  },
  {
    path: "/login",
    element: (
      <LoginLayout>
        <Login />
      </LoginLayout>
    ),
  },
  {
    path: "*",
    element: (
      <LoginLayout>
        <NotFound />
      </LoginLayout>
    ),
  },
  {
    path: "/signin-app",
    element: (
      <LoginLayout>
        <MagicLink />
      </LoginLayout>
    ),
  },
  {
    path: "/signin-link",
    element: (
      <LoginLayout>
        <SigninApp />
      </LoginLayout>
    ),
  },
  {
    path: "/sigin-link", //fix on email
    element: (
      <LoginLayout>
        <MagicLink />
      </LoginLayout>
    ),
  },
  {
    path: "/direct-link",
    element: (
      <LoginLayout>
        <SigninApp />
      </LoginLayout>
    ),
  },

  {
    path: "/content-submit/:bookId",
    element: (
      <LoginLayout>
        <SubmitContentRoute />
      </LoginLayout>
    ),
  },
  {
    path: "/submitted-content",
    element: (
      <DefaultLayout>
        <ContentSumitted />
      </DefaultLayout>
    ),
  },
  {
    path: "/tiktok-auth",
    element: (
      <LoginLayout>
        <TikTokAuth />
      </LoginLayout>
    ),
  },
  {
    path: "instagram-auth",
    element: (
      <LoginLayout>
        <InstagramAuth />
      </LoginLayout>
    ),
  },
  {
    path: "apple-auth",
    element: (
      <DefaultLayout>
        <AppleAuth />
      </DefaultLayout>
    ),
  },
  {
    path: "meta-auth",
    element: (
      <LoginLayout>
        <MetaAuth />
      </LoginLayout>
    ),
  },
  {
    path: "signin-sauce",
    element: (
      <LoginLayout>
        <SigninSauce />
      </LoginLayout>
    ),
  },
  {
    path: "dev-tools",
    element: isMobile ? (
      <MobileLayout>
        <DevTools />
      </MobileLayout>
    ) : (
      <DefaultLayout>
        <DevTools />
      </DefaultLayout>
    ),
  },
  {
    path: "/content",
    element: (
      <DefaultLayout>
        <BookSubmitContent />
      </DefaultLayout>
    ),
  },
  {
    path: "/outdated-app",
    element: (
      <DefaultLayout>
        <OutdatedApp />
      </DefaultLayout>
    ),
  },
]);

export default router;
