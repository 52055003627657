import { InstagramOutlined, TikTokOutlined } from "@ant-design/icons";
import { Divider } from "antd";
import { useNavigate } from "react-router-dom";

const CollabsFoodDrawerContent: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div style={{ width: "100%" }}>
      <span className="body-16-regular">
        Apply for local collabs with complimentaty meals
      </span>
      <div className="platform-item" style={{ marginTop: 20 }}>
        <div
          className="platform-details"
          style={{
            display: "flex",
            alignItems: "center",
            gap: 12,
            justifyContent: "space-between",
          }}
          onClick={() =>
            navigate("/creator-pledge", { state: { social: "instagram" } })
          }
        >
          <InstagramOutlined
            style={{ color: "#EB355F", fontSize: 20, alignSelf: "flex-start" }}
          />
          <div style={{ flex: 1 }}>
            <span className="subtitle-18-semi-bold-inter">Instagram</span>
            <p className="body-16-regular" style={{ margin: 0 }}>
              from 1k followers
            </p>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="arrow-icon"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
            width={12}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </div>
      </div>
      <Divider />
      <div className="platform-item">
        <div
          className="platform-details"
          style={{
            display: "flex",
            alignItems: "center",
            gap: 12,
            justifyContent: "space-between",
          }}
          onClick={() =>
            navigate("/creator-pledge", { state: { social: "tiktok" } })
          }
        >
          <TikTokOutlined
            style={{ color: "#EB355F", fontSize: 20, alignSelf: "flex-start" }}
          />
          <div style={{ flex: 1 }}>
            <span className="subtitle-18-semi-bold-inter">TikTok</span>
            <p className="body-16-regular" style={{ margin: 0 }}>
              from 1k followers
            </p>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="arrow-icon"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
            width={12}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default CollabsFoodDrawerContent;
