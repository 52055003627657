import { Avatar } from "antd";
import { Chat } from "types/chat";
import { getRelativeTimeString } from "../utils";

export const MustardMessage = ({
  content,
  state,
  createdAt,
  sender,
}: Chat["messages"][number]) => {
  return (
    <div>
      <div
        style={{
          display: "flex",
          wordBreak: "break-word",
          gap: 10,
          marginBottom: 10,
          flexDirection: "row",
          marginRight: 2,
        }}
      >
        <div>
          <Avatar src={sender.avatarUrl} />
        </div>
        <div className="message">{content}</div>
      </div>
      <div
        style={{
          paddingLeft: 50,
          fontSize: 12,
          color: "#999999",
        }}
      >
        {getRelativeTimeString(new Date(createdAt))}
      </div>
    </div>
  );
};
