import { message } from "antd";
import NavigationBar from "components/navigation-bar/NavigationBar";
import PhoneLogin from "pages/login/PhoneLogin";
import { useNavigate, useParams } from "react-router-dom";
import { updateUserContact } from "services/SauceService";
import EmailLogin from "./EmailLogin";
import { useDispatch } from "react-redux";
import { getLoggedUserInfo } from "redux-context/user";

const ConnectContact: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const { type } = useParams();

  const connectPhone = async (values: any) => {
    try {
      const params = {
        type: "phone",
        contact: values.phone,
        code: values.verificationCode,
      };
      const response = await updateUserContact(params);
      return response;
    } catch (error: any) {
      message.error(
        error?.message ? error.message : "Error on code verification"
      );
    }
  };

  const onLoginPhone = () => {
    dispatch(getLoggedUserInfo());
    navigate("/profile/notifications");
  };

  const onLoginEmail = () => {
    dispatch(getLoggedUserInfo());
    navigate("/profile/notifications");
  };

  return (
    <div className="login">
      <NavigationBar onBack={() => navigate(-1)} />

      <div style={{ margin: 20 }}>
        <div className="align-justify-center">
          {type === "phone" && (
            <PhoneLogin onLogin={onLoginPhone} connectFunction={connectPhone} />
          )}

          {type === "email" && <EmailLogin onLogin={onLoginEmail} />}
        </div>
      </div>
    </div>
  );
};

export default ConnectContact;
