import { useDispatch } from "react-redux";
import {
  createRoutesFromChildren,
  Outlet,
  useLocation,
  useNavigationType,
  matchRoutes,
} from "react-router-dom";
import { useEffect } from "react";
import { autenticado } from "helpers/auth-functions";
import {
  bootLocation,
  getLoggedUserInfo,
  getLoggedUserSocials,
} from "redux-context/user";
import * as Sentry from "@sentry/react";
import mixpanel from "mixpanel-browser";
import "./App.scss";
// import * as rdd from "react-device-detect";

// (rdd as any).isMobile = true;
// (rdd as any).isDesktop = false;
const { REACT_APP_MIXPANEL_TOKEN, REACT_APP_SENTRY_DSN } = process.env;

if (process.env.NODE_ENV === "production") {
  if (REACT_APP_MIXPANEL_TOKEN) {
    mixpanel.init(REACT_APP_MIXPANEL_TOKEN, {
      track_pageview: true,
    });
  }
  Sentry.init({
    dsn: REACT_APP_SENTRY_DSN,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],

    tracesSampleRate: 1.0,

    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

function App() {
  const dispatch = useDispatch<any>();

  // const { loading } = useSelector((state: any) => state.user);

  useEffect(() => {
    if (autenticado()) {
      dispatch(getLoggedUserInfo());
      dispatch(getLoggedUserSocials());
    }
    dispatch(bootLocation());
  }, [dispatch]);

  return <Outlet />;
}

export default App;
