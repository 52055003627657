import { Button, Form, Input, message } from "antd";
import { useCallback, useState } from "react";
import { sendVerificationCode, updateUserContact } from "services/SauceService";

enum Step {
  Email = "email",
  Code = "code",
}

type EmailConnectionParam = {
  email: string;
  verificationCode: string;
};

interface EmailLoginProps {
  title?: string;
  onLogin: (response: any) => void;
  connectFunction?: (params: EmailConnectionParam) => void;
}

const EmailLogin: React.FC<EmailLoginProps> = (props) => {
  const { title = "Email verification", onLogin, connectFunction } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
  const email = Form.useWatch<string>("email", {
    form,
  });
  const [step, setStep] = useState<Step>(Step.Email);
  const [timerToSendAgain, setTimerToSendAgain] = useState<number>();

  const startTimer = useCallback(() => {
    setTimerToSendAgain(60);
    const interval = setInterval(() => {
      setTimerToSendAgain((prev) => {
        if (prev === 0) {
          clearInterval(interval);
          return 0;
        }
        if (prev === undefined) {
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const signInEmailWithCode = async ({
    email,
    verificationCode,
  }: EmailConnectionParam) => {
    const params = {
      contact: email,
      code: verificationCode,
      type: "email",
    };
    try {
      const response: any = await updateUserContact(params);

      onLogin(response);
    } catch {
      message.error("Invalid code");
    }
    setLoading(false);
    return;
  };

  const onFinish = async () => {
    setLoading(true);
    const { email, verificationCode } = form.getFieldsValue(true);
    if (step === Step.Email) {
      startTimer();

      const params = {
        contact: email,
        type: "email",
      };
      try {
        await sendVerificationCode(params);
        setStep(Step.Code);
      } catch {
        message.error("Invalid email");
      }

      setLoading(false);
      return;
    }
    if (step === Step.Code) {
      if (connectFunction) connectFunction({ email, verificationCode });
      else signInEmailWithCode({ email, verificationCode });
      setLoading(false);
      return;
    }
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <div
        style={{
          display: step === Step.Email ? "block" : "none",
        }}
      >
        <span className="heading-1-32-bold" style={{ display: "block" }}>
          {title}
        </span>
        <span
          className="body-16-regular"
          style={{ display: "block", marginBottom: 20 }}
        >
          Receive only important notifications via Email
        </span>
        <Form.Item
          name="email"
          rules={[{ required: true, message: "Please input your email!" }]}
        >
          <Input type="email" />
        </Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          style={{ width: "100%" }}
          loading={loading}
        >
          Continue
        </Button>
      </div>
      {step === Step.Code && (
        <div>
          <span className="heading-1-32-bold" style={{ display: "block" }}>
            Verification code
          </span>
          <span
            className="body-16-regular"
            style={{ display: "block", marginBottom: 20 }}
          >
            Code sent to {email}
          </span>
          <div style={{ marginBottom: 24, height: 22 }}>
            {(timerToSendAgain || -1) > 0 && (
              <span className="timer-span">
                Resend the code in: 0:{timerToSendAgain}
              </span>
            )}
          </div>
          <Form.Item
            name="verificationCode"
            rules={[
              {
                required: true,
                message: "Please input the verification code!",
              },
            ]}
          >
            <Input.OTP
              length={6}
              onChange={() => form.submit()}
              style={{ width: "100%" }}
              mask
            />
          </Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{ width: "100%" }}
            loading={loading}
          >
            Send verification code
          </Button>
        </div>
      )}
    </Form>
  );
};

export default EmailLogin;
