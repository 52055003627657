import { useState } from "react";
import {
  Form,
  Input,
  Button,
  notification,
  message,
  Divider,
  QRCode,
} from "antd";
import { UserOutlined } from "@ant-design/icons";
import { generateMagicLink } from "services/SauceService";
import { ReactComponent as Logo } from "assets/images/logo.svg";
import { ReactComponent as ShortLogo } from "assets/images/short-logo.svg";
import PhoneLogin from "./PhoneLogin";
import { isMobile } from "react-device-detect";
import AppleButton from "components/apple-button/AppleButton";
import NavigationBar from "components/navigation-bar/NavigationBar";
import "./Login.scss";
import { useNavigate } from "react-router-dom";

enum LoginType {
  EMAIL = "email",
  PHONE = "phone",
  MAGIC_LINK = "magic-link",
}

const Login: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [api, contextHolder] = notification.useNotification();
  const [loginType, setLoginType] = useState<LoginType>(LoginType.EMAIL);

  // const dispatch = useDispatch<any>();

  const [form] = Form.useForm();

  const onFinish = async () => {
    setLoading(true);
    const values = form.getFieldsValue(true);
    if (loginType === LoginType.MAGIC_LINK) {
      try {
        await generateMagicLink(values.email);
        message.success("Magic link sent ✨🪄✨");
        setLoading(false);
      } catch (error) {
        setLoading(false);
        api.error({
          message: "Error",
          description: "Invalid email",
        });
      }
    }
  };

  const onLoginPhone = (response: any) => {
    // console.log("response", response);
    navigate("/signin-link?accessToken=" + response.accessToken);
  };

  // const handleLoginMagicLink = () => {
  //   setLoginType(LoginType.MAGIC_LINK);
  // };

  const handleLoginPhone = () => {
    setLoginType(LoginType.PHONE);
  };

  if (loginType === LoginType.PHONE) {
    return (
      <div className="login">
        <NavigationBar onBack={() => setLoginType(LoginType.EMAIL)} />
        {contextHolder}
        <div style={{ margin: 20 }}>
          <div className="align-justify-center">
            <PhoneLogin onLogin={onLoginPhone} title="Log in with Mustard" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="login">
      <NavigationBar
        onBack={() => setLoginType(LoginType.EMAIL)}
        hidden={loginType === LoginType.EMAIL}
      />
      {contextHolder}
      <div className="align-justify-center">
        <div
          className="container"
          style={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            justifyContent: "center",
            gap: isMobile ? 0 : 30,
          }}
        >
          <div>
            <div className="logo">
              <Logo style={{ width: 184, marginBottom: 8 }} />
              <span className="caption-regular">
                Find local collabs and get paid
              </span>
            </div>

            <div style={{ display: "flex", flexDirection: "column", gap: 12 }}>
              <Button
                style={{ borderRadius: 30 }}
                icon={
                  <span role="img" aria-label="phone">
                    📞
                  </span>
                }
                onClick={handleLoginPhone}
              >
                Continue with Phone number
              </Button>
              <AppleButton />
            </div>
          </div>

          {isMobile ? (
            <Divider style={{ margin: "16px 0" }}>Or</Divider>
          ) : (
            <div
              style={{
                width: 1,
                backgroundColor: "#ddd",
                position: "relative",
                margin: "0 16px",
              }}
            >
              <span
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  background: "#fff", // se quiser um fundo para “cortar” a linha
                  padding: 4,
                }}
              >
                Or
              </span>
            </div>
          )}

          {isMobile ? (
            <div
              className="align-justify-center"
              style={{ flexDirection: "column" }}
            >
              <h1 className="heading-3-22-semi-bold" style={{ margin: 0 }}>
                Log in with Mustard App
              </h1>
              <span className="caption-13-regular">
                Download the app for iOS
              </span>
              <button
                className="black-button"
                style={{ display: "flex", marginTop: 24 }}
                onClick={() =>
                  window.open(
                    "https://apps.apple.com/us/app/mustard-love/id6677056714",
                    "_blank",
                    "noopener noreferrer"
                  )
                }
              >
                <ShortLogo
                  style={{ position: "absolute", width: 22, height: 16 }}
                />
                <span style={{ flex: 1 }}>Log in</span>
              </button>
            </div>
          ) : (
            <div
              className="align-justify-center"
              style={{ flexDirection: "column", width: 300 }}
            >
              <h1 className="heading-2-26-bold" style={{ marginTop: 0 }}>
                Log in with Mustard App
              </h1>
              <span className="subtitle-18-regular">
                Scan the QR code to download the app for iOS
              </span>
              <QRCode
                style={{ marginTop: 24 }}
                value="https://apps.apple.com/us/app/mustard-love/id6677056714"
              />
            </div>
          )}
          <Form
            form={form}
            name="login"
            onFinish={onFinish}
            style={{ width: 300, display: "none" }}
          >
            {loginType === LoginType.EMAIL && (
              <div style={{ textAlign: "center" }}>
                {/* <div style={{ display: "flex", justifyContent: "space-around" }}>
                <TikTokButton />
                <Button
                  style={{ borderRadius: 12 }}
                  icon={<LinkOutlined />}
                  onClick={handleLoginMagicLink}
                />

                <InstagramButton />
              </div> */}
              </div>
            )}
            {loginType === LoginType.MAGIC_LINK && (
              <div>
                <h2>Magic Link</h2>
                <Form.Item
                  name="email"
                  rules={[
                    { required: true, message: "Please input your email!" },
                  ]}
                >
                  <Input prefix={<UserOutlined />} placeholder="Email" />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                    loading={loading}
                  >
                    Send Magic Link
                  </Button>
                </Form.Item>
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <Button
                    type="link"
                    onClick={() => setLoginType(LoginType.EMAIL)}
                  >
                    Back
                  </Button>
                </div>
              </div>
            )}
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Login;
