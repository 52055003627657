import { DietaryPreferences } from "types/dietary-preferences";

export enum ProfileStatus {
  Active = 0,
  SocialPending = 1,
  ChooseSocialMedia = 2,
  LocationPending = 3,

  MissingPhone = 4,
  MissingName = 5,
  OutdatedAppVersion = 6,
}

export interface UserProfile {
  _id: string;
  email: string;
  phone: string;
  name: string;
  mustardUserName: string;
  collabReputation: number;
  avatarUrl: string;
  bio: string;
  socialMedias: SocialMedia[];
  locations: Location[];
  categories: string[];
  stripeId: string;
  dietaryPreferences: DietaryPreferences;
  notificationPreferences: NotificationPreferences;
  profileStatus: ProfileStatus;
  reputationDetails: CollabReputation;
  verificationNeeded: boolean;
  //error management
  aborted: boolean;
}

export interface CollabReputation {
  amountApplied: number;
  amountCanceled: number;
  amountCompleted: number;
  amountRescheduled: number;
}
export interface SocialMedia {
  socialMediaName: string;
  socialMediaUserName: string;
  socialMediaUrl: string;
}

export interface Location {
  name: string;
  lat: number;
  long: number;
}

export type ConnectedSocial = {
  dateConnected?: string;
  notInterested?: boolean;
  socialMediaName: "tiktok" | "instagram" | "meta";
  userName?: string;
};
// export interface DietaryPreferences {
//   none: boolean;
//   vegetarian: boolean;
//   vegan: boolean;
//   lactoseIntolerance: boolean;
//   glutenIntolerance: boolean;
//   noPork: boolean;
//   nutsAllergy: boolean;
//   pescatarian: boolean;
//   shellfishAllergy: boolean;
//   other: boolean;
//   otherText: string;
// }

export interface NotificationPreferences {
  foodComp: boolean;
  cashComp: boolean;
  affiliateComp: boolean;
  keepPostedOverEmail: boolean;
  keepPostedOverText: boolean;
  keepPostedOverNotifications: boolean;
  stopSendingCommunication: boolean;
}
