import { Button } from "antd";
import creatorPledge from "assets/images/creator-pledge.png";
import { handleLoginInstagram } from "components/instagram-button/InstagramButton";
import NavigationBar from "components/navigation-bar/NavigationBar";
import { handleLoginTikTok } from "components/tiktok-buttom/TikTokButton";
import { openUrl } from "helpers/link-helper";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { hideMenu, showMenu } from "redux-context/page";

const CreatorPledge: React.FC = () => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const { social }: { social: "instagram" | "tiktok" | "facebook" } =
    state || {};

  const onClickPolicy = () => {
    openUrl("https://www.mustard.love/reschedule-and-cancellation-policy");
  };

  const onClickAgree = () => {
    if (social === "instagram") {
      handleLoginInstagram();
      return;
    }

    if (social === "tiktok") {
      handleLoginTikTok();
      return;
    }

    if (social === "facebook") {
      navigate("/facebook-disclaimer");
      return;
    }
  };

  useEffect(() => {
    dispatch(hideMenu());
    return () => {
      dispatch(showMenu());
    };
  }, [dispatch]);

  return (
    <div className="page-full-height" style={{ padding: 0, width: "auto" }}>
      <NavigationBar onBack={() => navigate(-1)} />
      <div
        style={{
          padding: "0 20px",
          flex: 1,
        }}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img src={creatorPledge} alt="" style={{ width: 220 }} />
        </div>
        <h1 className="heading-1-32-bold">Mustard Creator Pledge</h1>
        <ul className="welcome-card__steps-list">
          <li className="welcome-card__step-item">
            ⭐️
            <div>
              <p className="welcome-card__step-description body-16-regular">
                <b>I pledge</b> not to use any techniques to artificially
                increase my engagement.
              </p>
            </div>
          </li>
          <li className="welcome-card__step-item">
            💸
            <div>
              <p className="welcome-card__step-description body-16-regular">
                <b>I will respect</b> my community and commit to avoiding
                no-shows for collabs.
              </p>
            </div>
          </li>
          <li className="welcome-card__step-item">
            🛎
            <div>
              <p className="welcome-card__step-description body-16-regular">
                <b>I will always</b> share my genuine opinion about the food and
                locations I visit, as outlined in the{" "}
                <Button
                  type="link"
                  onClick={onClickPolicy}
                  style={{
                    padding: 0,
                    color: "#1677ff",
                    fontSize: 16,
                    height: "auto",
                  }}
                >
                  no-shows policy.
                </Button>
              </p>
            </div>
          </li>
        </ul>
      </div>
      <div
        style={{
          padding: 20,
        }}
      >
        <Button
          type="primary"
          size="large"
          shape="round"
          onClick={onClickAgree}
          style={{ marginTop: 24, width: "100%" }}
        >
          I agree
        </Button>
      </div>
    </div>
  );
};

export default CreatorPledge;
