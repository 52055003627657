import {
  InstagramOutlined,
  LeftOutlined,
  TikTokOutlined,
} from "@ant-design/icons";
import { Divider, Row, Typography } from "antd";
import { useNavigate } from "react-router-dom";
// import { ReactComponent as InstagramIcon } from "assets/images/instagram-icon.svg";
// import { ReactComponent as TikTokIcon } from "assets/images/tiktok-icon.svg";
import { ConnectedSocial, ProfileStatus } from "interfaces/user-profile";
import { ReactComponent as LogoutIcon } from "assets/images/logout-icon.svg";
import { ReactComponent as FacebookIcon } from "assets/images/facebook-icon.svg";
import { logout } from "helpers/auth-functions";
// import FacebookButton from "components/facebook-button/FacebookButton";
import { useSelector } from "react-redux";
import { handleLoginInstagram } from "components/instagram-button/InstagramButton";
import { handleLoginTikTok } from "components/tiktok-buttom/TikTokButton";
import { UserState } from "redux-context/user";

interface SocialMediaProps {
  // Add any props you need here
}

const SocialMedia: React.FC<SocialMediaProps> = () => {
  const navigate = useNavigate();
  const { loggedUser, connectedSocials } = useSelector(
    (state: UserState) => state.user
  );

  const verification = loggedUser?.profileStatus !== ProfileStatus.Active;

  const { instagram, tiktok, meta } = connectedSocials?.reduce(
    (acc, social) => {
      acc[social.socialMediaName] = social;
      return acc;
    },
    {
      instagram: { socialMediaName: "instagram" },
      tiktok: { socialMediaName: "tiktok" },
      meta: { socialMediaName: "meta" },
    } as Record<string, ConnectedSocial>
  ) || { instagram: null, tiktok: null, meta: null };

  const onClickUnlockInstagram = () => {
    handleLoginInstagram();
  };

  const onClickUnlockTikTok = () => {
    handleLoginTikTok();
  };

  const onClickUnlockFacebook = () => {
    navigate("/facebook-disclaimer");
  };

  const onLogoutClick = () => {
    logout();
  };

  return (
    <div className="social-media profile">
      <Row
        style={{ alignItems: "center", marginTop: 20 }}
        justify="space-between"
      >
        <div style={{ display: "flex" }}>
          {!verification && (
            <LeftOutlined
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            />
          )}
          <Typography.Title level={3} style={{ margin: "auto 20px" }}>
            Social media
          </Typography.Title>
        </div>
        {verification && (
          <div
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
          >
            <LogoutIcon
              style={{ width: 20, height: 20 }}
              onClick={() => onLogoutClick()}
            />
          </div>
        )}
      </Row>
      <div className="content" style={{ gap: 0 }}>
        <span className="body-16-regular">
          {/* Apply for local collabs with complimentaty meals */}
        </span>
        <div className="platform-item" style={{ marginTop: 20 }}>
          <div
            className="platform-details"
            style={{
              display: "flex",
              alignItems: "center",
              gap: 12,
              justifyContent: "space-between",
            }}
            onClick={onClickUnlockFacebook}
          >
            <FacebookIcon style={{ alignSelf: "flex-start" }} />
            <div style={{ flex: 1 }}>
              <span className="subtitle-18-semi-bold-inter">
                Meta Business Account
              </span>
              {meta?.dateConnected ? (
                <p className="body-16-regular" style={{ margin: 0 }}>
                  @{meta.userName}
                </p>
              ) : (
                <p className="body-16-regular" style={{ margin: 0 }}>
                  from 1k followers
                </p>
              )}
            </div>
            {meta?.dateConnected ? (
              <span className="body-16-regular">Connected</span>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="arrow-icon"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
                width={12}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            )}
          </div>
        </div>
        <Divider />
        <div className="platform-item">
          <div
            className="platform-details"
            style={{
              display: "flex",
              alignItems: "center",
              gap: 12,
              justifyContent: "space-between",
            }}
            onClick={onClickUnlockInstagram}
          >
            <InstagramOutlined
              style={{
                color: "#EB355F",
                fontSize: 20,
                alignSelf: "flex-start",
              }}
            />
            <div style={{ flex: 1 }}>
              <span className="subtitle-18-semi-bold-inter">Instagram</span>
              {instagram?.dateConnected ? (
                <p className="body-16-regular" style={{ margin: 0 }}>
                  @{instagram.userName}
                </p>
              ) : (
                <p className="body-16-regular" style={{ margin: 0 }}>
                  from 1k followers
                </p>
              )}
            </div>
            {instagram?.dateConnected ? (
              <span className="body-16-regular">Connected</span>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="arrow-icon"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
                width={12}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            )}
          </div>
        </div>
        <Divider />
        <div className="platform-item">
          <div
            className="platform-details"
            style={{
              display: "flex",
              alignItems: "center",
              gap: 12,
              justifyContent: "space-between",
            }}
            onClick={onClickUnlockTikTok}
          >
            <TikTokOutlined
              style={{
                color: "#EB355F",
                fontSize: 20,
                alignSelf: "flex-start",
              }}
            />
            <div style={{ flex: 1 }}>
              <span className="subtitle-18-semi-bold-inter">TikTok</span>
              {tiktok?.dateConnected ? (
                <p className="body-16-regular" style={{ margin: 0 }}>
                  @{tiktok.userName}
                </p>
              ) : (
                <p className="body-16-regular" style={{ margin: 0 }}>
                  from 1k followers
                </p>
              )}
            </div>
            {tiktok?.dateConnected ? (
              <span className="body-16-regular">Connected</span>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="arrow-icon"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
                width={12}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialMedia;
