import { Button } from "antd";
import { useConstantDrawer } from "hooks/useDrawer";
import ProfileAllSocialDrawer from "./ProfileAllSocialDrawer";
import { useSelector } from "react-redux";
import { UserState } from "redux-context/user";
import { ConnectedSocial } from "interfaces/user-profile";

const ProfileAllSocialConnect: React.FC = () => {
  const { connectedSocials, loadingSocial } = useSelector(
    (state: UserState) => state.user
  );

  const { instagram, tiktok, meta } = connectedSocials?.reduce(
    (acc, social) => {
      acc[social.socialMediaName] = social;
      return acc;
    },
    {
      instagram: { socialMediaName: "instagram" },
      tiktok: { socialMediaName: "tiktok" },
      meta: { socialMediaName: "meta" },
    } as Record<string, ConnectedSocial>
  ) || { instagram: null, tiktok: null, meta: null };

  const drawerSocial = useConstantDrawer(
    <ProfileAllSocialDrawer />,
    "Connect social media",
    400
  );

  const isSocialConnectedOrNotInterested = (social: ConnectedSocial | null) =>
    social && (social.notInterested === true || social.dateConnected !== null);
  if (
    loadingSocial ||
    (isSocialConnectedOrNotInterested(meta) &&
      isSocialConnectedOrNotInterested(tiktok) &&
      isSocialConnectedOrNotInterested(instagram))
  ) {
    return null;
  }

  const renderUnlockSection = (
    social: ConnectedSocial | null,
    title: string,
    description: string
  ) => {
    if (
      !social ||
      (social.notInterested !== true && social.dateConnected === null)
    ) {
      return (
        <div style={{ width: "100%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: 16,
            }}
          >
            🔒
            <span className="subtitle-18-semi-bold-inter" style={{ flex: 1 }}>
              {title}
            </span>
            <Button
              type="primary"
              size="small"
              shape="round"
              onClick={drawerSocial.openDrawer}
            >
              Unlock
            </Button>
          </div>
          <span className="caption-13-regular" style={{ marginLeft: 34 }}>
            {description}
          </span>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="content" style={{ borderRadius: 16, padding: 20 }}>
      <div
        className="highlight"
        style={{ display: "flex", flexDirection: "column", gap: 20 }}
      >
        {renderUnlockSection(
          meta,
          "Unlock Meta Business",
          "get paid for your video content"
        )}
        {renderUnlockSection(
          tiktok,
          "Unlock TikTok Creator",
          "unlock TikTok collabs and complementary meals"
        )}
        {renderUnlockSection(
          instagram,
          "Unlock Instagram Creator",
          "unlock Instagram collabs and complementary meals"
        )}
      </div>
      {drawerSocial.DrawerComponent}
    </div>
  );
};

export default ProfileAllSocialConnect;
