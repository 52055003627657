import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getUserLoggedInfo,
  getUserLoggedSocialMedias,
  verifyStripeStatus,
} from "services/SauceService";
import mixpanel from "mixpanel-browser";
import { ConnectedSocial, UserProfile } from "interfaces/user-profile";
import { StripeStatus } from "interfaces/stripe-status";

export const getLoggedUserInfo = createAsyncThunk(
  "user/getLoggedUserInfo",
  async () => {
    const userInfo: any = await getUserLoggedInfo();
    return userInfo;
  }
);

export const getLoggedUserSocials = createAsyncThunk(
  "user/getLoggedUserSocials",
  async () => {
    const userSocials: any = await getUserLoggedSocialMedias();
    return userSocials;
  }
);

export const getStripeAccount = createAsyncThunk(
  "user/getStripeAccount",
  async (data: any) => {
    const stripeAccount: any = await verifyStripeStatus(data);
    return stripeAccount;
  }
);

export interface FindLocationCollabs {
  city: string;
  coordinates: {
    lat: number;
    lng: number;
  };
}

export type UserState = {
  user: {
    loading: boolean;
    loadingSocial: boolean;
    loggedUser: UserProfile;
    stripeConnected: boolean;
    stripeInfo: StripeStatus;
    stripeStatus: StripeStatus;
    socialConnected: boolean;
    connectedSocials: ConnectedSocial[];
  };
};

export const userSlice: any = createSlice({
  name: "user",
  initialState: {
    loading: true,
    loadingSocial: true,
    loggedUser: {},
    stripeConnected: false,
    stripeInfo: null,
    findCollabslocation: {},
    socialConnected: false,
    connectedSocials: null,
  },
  reducers: {
    setLocation: (state, action) => {
      state.findCollabslocation = action.payload;
      localStorage.setItem(
        "findCollabslocation",
        JSON.stringify(action.payload)
      );
    },
    bootLocation: (state) => {
      const location = localStorage.getItem("findCollabslocation");
      if (location) {
        state.findCollabslocation = JSON.parse(location);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLoggedUserInfo.pending, (state) => {
      state.loggedUser = {};
      state.loading = true;
    });

    builder.addCase(getLoggedUserInfo.fulfilled, (state, action) => {
      if (action.payload) {
        state.loggedUser = action.payload;
        state.socialConnected = action.payload.socialMedias?.length > 0;
        localStorage.setItem("foodieId", action.payload._id);
        try {
          mixpanel?.identify(action.payload._id);
        } catch {}
      }
      state.loading = false;
    });

    builder.addCase(getLoggedUserInfo.rejected, (state, action) => {
      if (action.error?.code === "ERR_NETWORK") {
        state.loggedUser = {
          aborted: true,
          result: action,
        };
      } else {
        state.loggedUser = {};
      }

      state.loading = false;
    });

    builder.addCase(getLoggedUserSocials.pending, (state) => {
      state.loadingSocial = true;
    });

    builder.addCase(getLoggedUserSocials.fulfilled, (state, action) => {
      if (action.payload) {
        state.connectedSocials = action.payload;
        state.loadingSocial = false;
      }
    });

    builder.addCase(getLoggedUserSocials.rejected, (state, action) => {
      state.connectedSocials = null;
      state.loadingSocial = false;
    });

    builder.addCase(getStripeAccount.fulfilled, (state, action) => {
      if (action.payload) {
        state.stripeConnected = action.payload.complete;
        state.stripeInfo = action.payload;
      }
    });
  },
});

export const { setLocation, bootLocation } = userSlice.actions;

export default userSlice.reducer;
